import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useCallback, useMemo, useState } from "react";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import { useTranslation } from "react-i18next";

const SubHeader = styled("span")(() => ({
  fontSize: 14,
  margin: "-3px 0 -5px 0",
}));

type RouteView = {
  id: string;
  name: string | null;
  waypointNote: string | null;
  hasCargos: boolean;
  firstStop: { city: string | null } | null;
  lastStop: { city: string | null } | null;
};

export const getRouteViewName = (routeView?: RouteView) => {
  if (routeView?.name) {
    return routeView?.name;
  }
  // Commented out because we might bring it back
  // if (routeView?.hasCargos && routeView?.waypointNote) {
  // it's most likely a newly created route with a waypoint date note
  //   return `${routeView?.firstStop?.city} ${routeView?.waypointNote}`;
  // }
  // return [routeView?.firstStop?.city, routeView?.lastStop?.city]
  //   .filter((s) => !!s)
  //   .join(" - ");
};

export const RouteName = (props: {
  onSaveCustomName: (typedCustomName: string) => void;
  routeView?: RouteView;
  iconToTheLeft?: boolean;
}) => {
  const { routeView, onSaveCustomName } = props;
  const { t } = useTranslation("dispatching");
  const [editingName, setEditingName] = useState(false);
  const [typedCustomName, setTypedCustomName] = useState(routeView?.name || "");

  const subheaderText = useMemo(() => {
    const routeNameBasedOnStops = getRouteViewName(routeView);
    return routeNameBasedOnStops || `${t("routeCard.addRouteName")}`;
  }, [routeView, t]);

  const showRouteNameEditField = useCallback(() => {
    setEditingName(true);
  }, []);

  const saveCustomName = useCallback(() => {
    setEditingName(false);
    onSaveCustomName(typedCustomName);
  }, [onSaveCustomName, typedCustomName]);

  const saveOnEnterPressed = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Enter") {
        saveCustomName();
      }
    },
    [saveCustomName]
  );

  if (editingName) {
    return (
      <TextField
        value={typedCustomName}
        onChange={(event) => setTypedCustomName(event.target.value)}
        placeholder={t("routeCard.addRouteName")}
        onBlur={saveCustomName}
        // onClick={preventClickPropagation} why?
        autoFocus
        variant="standard"
        sx={{ margin: "-3px 0 -5px 0" }}
        InputProps={{ disableUnderline: true }}
        onKeyDown={saveOnEnterPressed}
      />
    );
  }
  return (
    <SubHeader onClick={showRouteNameEditField}>
      {props.iconToTheLeft && (
        <EditLocationAltIcon
          sx={{
            color: "#868686",
            fontSize: 17,
            mr: "5px",
            mb: "-2px",
          }}
        />
      )}
      {subheaderText}
      {!props.iconToTheLeft && (
        <EditLocationAltIcon
          sx={{
            color: "#979797",
            fontSize: 16,
            ml: "5px",
            mb: "-2px",
          }}
        />
      )}
    </SubHeader>
  );
};

export default RouteName;
