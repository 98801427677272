// import * as pdfJsLib from "pdfjs-dist";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import DoraLinkButton, { DoraLink } from "../../../Toolkit/DoraLinkButton";
import "./DocumentsCard.scss";
import axios from "../../../../axios";
import ExistingCargoDocumentsList from "./ExistingCargoDocumentsList";
import { useMemo, useState } from "react";
import { useAppDispatch, useSelector } from "../../../../redux-store";
import { notify } from "../../../../ducks/notifications";
import { loadDocuments } from "../../../../ducks/data/cargo-documents";
import GenericPromptDialog from "../../GenericPromptDialog/GenericPromptDialog";
import { useFeature } from "../../../../hooks";
import { selectCargoEvents } from "../../../../ducks/data/cargo-events/selectors";

// pdfJsLib.GlobalWorkerOptions.workerSrc =
//   "https://unpkg.com/pdfjs-dist/build/pdf.worker.js";

type DocumentsCardProps = {
  createNew: boolean;
  cargoId: string | null;
  pickupNumber: number;
  dropoffNumber: number;
};

// type FileWrapper = {
//   id: number;
//   file: File;
//   type: "pdf" | "image";
//   preview?: {
//     url?: string;
//     data?: string;
//   };
// };

const DocumentsCard = (props: DocumentsCardProps) => {
  const { createNew, cargoId, pickupNumber, dropoffNumber } = props;
  const [documentIdToDelete, setDocumentIdToDelete] = useState<string | null>(
    null
  );
  const [documentsChangeInProgress, setDocumentsChangeInProgress] =
    useState(false);
  const { t } = useTranslation(["shipmentModal", "components"]);
  const areDriverLogDocsEnabled = useFeature("driver-report-for-cargo");
  const liteVersion = useFeature("lite-version");
  const dispatch = useAppDispatch();
  const cargoEvents = useSelector(selectCargoEvents);
  const showDriverLogLinks = useMemo(
    () =>
      !!cargoEvents?.filter((e) =>
        [
          "CARGO_COLLECTED",
          "CARGO_DELIVERED",
          "DRIVER_ARRIVED",
          "DRIVER_DEPARTED",
        ].includes(e.type)
      ).length,
    [cargoEvents]
  );

  const printCmrPdf = (options: {
    pickupIndex: number;
    dropoffIndex: number;
  }) => {
    const params = {
      cargoId,
      pickup: options.pickupIndex,
      dropoff: options.dropoffIndex,
    };
    axios.get("/api/cmr/pdf", { params, responseType: "blob" }).then((res) => {
      const file = new Blob([res.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    });
  };

  // const generatePreview = (fileWrapper: FileWrapper) => {
  //   switch (fileWrapper.type) {
  //     case "pdf":
  //       generatePdfPreview(fileWrapper);
  //       break;
  //     case "image":
  //       generateImagePreview(fileWrapper);
  //       break;
  //     default:
  //       throw new Error("Unknown file type");
  //   }
  // };

  // const generatePdfPreview = (fileWrapper: FileWrapper) => {
  //   const fileReader = new FileReader();
  //   fileReader.onload = () => {
  //     pdfJsLib
  //       .getDocument({ data: fileReader.result as any })
  //       .promise.then((pdf) => {
  //         pdf.getPage(1).then(function (page) {
  //           const desiredWidth = 400;
  //           const viewport = page.getViewport({ scale: 1 });
  //           const scale = desiredWidth / viewport.width;
  //           const scaledViewport = page.getViewport({ scale: scale });
  //
  //           const canvas = document.createElement("canvas");
  //           const context = canvas.getContext("2d");
  //
  //           canvas.height = scaledViewport.height;
  //           canvas.width = scaledViewport.width;
  //
  //           const renderContext = {
  //             canvasContext: context,
  //             viewport: scaledViewport,
  //           };
  //           const renderTask = page.render(renderContext as any);
  //           renderTask.promise.then(function () {
  //             append({
  //               ...fileWrapper,
  //               preview: { url: canvas.toDataURL("image/png") },
  //             });
  //             canvas.remove();
  //           });
  //         });
  //       });
  //   };
  //   fileReader.readAsArrayBuffer(fileWrapper.file);
  // };
  //
  // const generateImagePreview = (fileWrapper: FileWrapper) => {
  //   const fileReader = new FileReader();
  //   fileReader.onload = () => {
  //     append({
  //       ...fileWrapper,
  //       preview: { url: fileReader.result },
  //     });
  //   };
  //   fileReader.readAsDataURL(fileWrapper.file);
  // };

  const onFilesAdded = async (acceptedFiles: File[]) => {
    if (!acceptedFiles.length) {
      // this only happens when a drag drop operation is done with an unaccepted file type
      dispatch(
        notify({
          message: t(
            `shipmentModal:cards.documents.notificationErrorNotAccepted`
          ),
          type: "error",
        })
      );
      return;
    }
    setDocumentsChangeInProgress(true);
    const formData = new FormData();
    for (let f of acceptedFiles) {
      formData.append("files", new Blob([f], { type: f.type }), f.name);
    }
    await axios
      .post(`/api/cargos/${cargoId}/documents`, formData)
      .then(() => {
        dispatch(loadDocuments(cargoId as string));
        dispatch(
          notify({
            message: t(`cards.documents.notificationSuccess`, {
              count: acceptedFiles.length,
            }),
            type: "success",
          })
        );
      })
      .catch((err) => {
        console.error(err);
        dispatch(
          notify({
            message: t(`cards.documents.notificationError`, {
              count: acceptedFiles.length,
            }),
            type: "error",
          })
        );
      })
      .finally(() => {
        setDocumentsChangeInProgress(false);
      });
  };

  const removeDocument = async () => {
    setDocumentsChangeInProgress(true);
    await axios
      .delete(`/api/cargos/${cargoId}/documents/${documentIdToDelete}`)
      .then(() => {
        dispatch(
          notify({
            message: t(
              "shipmentModal:cards.documents.notificationRemoveSuccess"
            ),
            type: "success",
          })
        );
        setDocumentIdToDelete(null);
        dispatch(loadDocuments(cargoId as string));
      })
      .catch((err) => {
        console.error(err);
        dispatch(
          notify({
            message: t("shipmentModal:cards.documents.notificationRemoveError"),
            type: "error",
          })
        );
      })
      .finally(() => {
        setDocumentsChangeInProgress(false);
      });
  };

  let idx = 1;

  const extraPickupsCmrDownloads =
    pickupNumber > 1
      ? new Array(pickupNumber - 1).fill(0).map((_, i) => (
          <div className="documents-card__list-item" key={i}>
            <DoraLinkButton
              onClick={() =>
                printCmrPdf({ pickupIndex: i + 1, dropoffIndex: 0 })
              }
            >
              {t("shipmentModal:cards.documents.downloadCmrX", { id: idx++ })}
            </DoraLinkButton>
          </div>
        ))
      : null;
  const extraDropoffsCmrDownloads =
    dropoffNumber > 1
      ? new Array(dropoffNumber - 1).fill(0).map((_, i) => (
          <div className="documents-card__list-item" key={i}>
            <DoraLinkButton
              onClick={() =>
                printCmrPdf({ pickupIndex: 0, dropoffIndex: i + 1 })
              }
            >
              {t("shipmentModal:cards.documents.downloadCmrX", { id: idx++ })}
            </DoraLinkButton>
          </div>
        ))
      : null;

  return (
    <div className="documents-card">
      {documentIdToDelete && (
        <GenericPromptDialog
          title={t("shipmentModal:cards.documents.confirmRemoveDocumentTitle")}
          text={t("shipmentModal:cards.documents.confirmRemoveDocumentMessage")}
          continueButtonColor={"error"}
          continueText={t("components:buttonLabels.remove")}
          onCancelClick={() => setDocumentIdToDelete(null)}
          onContinueClick={() => removeDocument()}
        />
      )}
      <div className="flex justify-between items-center">
        <h3 className="documents-card__title">
          {t("shipmentModal:cards.documents.title")}
        </h3>
        {!liteVersion && !createNew && (
          <Dropzone
            maxSize={50000000}
            accept={{
              "image/png": [".png"],
              "image/jpeg": [".jpeg", ".jpg"],
              "application/pdf": [".pdf"],
              "application/vnd.ms-word": [".doc", ".docx"],
              "application/vnd.ms-excel": [".xls", ".xlsx"],
              "application/vnd.apple.numbers": [".numbers"],
              "message/rfc822": [".eml"],
              "application/vnd.ms-outlook": [".pst", ".ost"],
              "text/calendar": [".ics"],
            }}
            onDrop={onFilesAdded}
            disabled={documentsChangeInProgress}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <DoraLinkButton
                  className="flex gap-2"
                  disabled={documentsChangeInProgress}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  {t("shipmentModal:cards.documents.upload")}
                </DoraLinkButton>
              </div>
            )}
          </Dropzone>
        )}
      </div>
      {createNew && (
        <div className="documents-card__placeholder-text">
          {liteVersion
            ? "Opret ordren for at se CMR"
            : t("shipmentModal:cards.documents.availableLater")}
        </div>
      )}
      <div className="documents-card__list">
        {!createNew && (
          <>
            {areDriverLogDocsEnabled && showDriverLogLinks && (
              <>
                <div className="documents-card__list-item">
                  <DoraLink
                    className="flex items-center gap-2 whitespace-nowrap"
                    url={`/api/mileage-reports/pdf-for-cargo?cargoId=${cargoId}`}
                    newTab
                  >
                    <FontAwesomeIcon icon={faFile} />
                    {t("shipmentModal:cards.documents.downloadDriverReportPDF")}
                  </DoraLink>
                </div>
                <div className="documents-card__list-item">
                  <DoraLink
                    className="flex items-center gap-2 whitespace-nowrap"
                    url={`/api/mileage-reports/csv-for-cargo?cargoId=${cargoId}`}
                    newTab
                  >
                    <FontAwesomeIcon icon={faFile} />
                    {t("shipmentModal:cards.documents.downloadDriverReportCSV")}
                  </DoraLink>
                </div>
              </>
            )}
            <div className="documents-card__list-item">
              <DoraLinkButton
                className="flex items-center gap-2 whitespace-nowrap"
                onClick={() => printCmrPdf({ pickupIndex: 0, dropoffIndex: 0 })}
              >
                <FontAwesomeIcon icon={faFile} />
                {t("shipmentModal:cards.documents.downloadCmr")}
              </DoraLinkButton>
            </div>
            {extraPickupsCmrDownloads} {extraDropoffsCmrDownloads}
          </>
        )}
        {cargoId && (
          <ExistingCargoDocumentsList
            onRemoveItemClick={setDocumentIdToDelete}
            cargoId={cargoId}
          />
        )}
      </div>
    </div>
  );
};

export default DocumentsCard;
