import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const prefix = "app/cargo-cards-hover";

interface UndispatchedCargoCardsHoverState {
  cargoId: string | null;
}

const initialState: UndispatchedCargoCardsHoverState = {
  cargoId: null,
};

const cargoCardsHoverSlice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setHoveredUndispatchedCargoId: (state, action: PayloadAction<string | null>) => {
      state.cargoId = action.payload;
    },
  },
});

export const { setHoveredUndispatchedCargoId } = cargoCardsHoverSlice.actions;
export default cargoCardsHoverSlice.reducer;
