import { Grid, Slider, Typography } from "@mui/material";
import { FC, useState } from "react";
import { debounce } from "../../../helpers/debounce";
import { useTranslation } from "react-i18next";

export const defaultRadius = 50;

// Only call method when it hasn't been called in 500ms
const updateRadiusDebounced = debounce(
  (onChange: Function, radius: number) => onChange(radius),
  500
);

interface Props {
  onChange: (radius: number) => void;
  initialState: number | undefined | null;
}
export const Radius: FC<Props> = (props) => {
  const { t } = useTranslation(["dispatching", "translation"]);
  const [radius, setRadius] = useState(props.initialState || defaultRadius);

  const handleRadiusChange = async (
    _event: Event,
    newRadius: number | number[]
  ) => {
    setRadius(newRadius as number);
    updateRadiusDebounced(props.onChange, newRadius);
  };

  return (
    <Grid container direction="column">
      <Grid container justifyContent="space-between">
        <Typography>{t("dispatching:openRouteView.radius")}</Typography>
        <Typography color="textSecondary">
          {radius}
          {t("translation:measurements.abbrev.kilometer")}
        </Typography>
      </Grid>
      <Grid item>
        <Slider
          value={radius}
          min={10}
          max={200}
          step={5}
          valueLabelDisplay="off"
          size="small"
          onChange={handleRadiusChange}
        />
      </Grid>
    </Grid>
  );
};
