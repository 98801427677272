import { RootState } from "../../../redux-store";

export const selectRouteLogin = (routeId: string) => (state: RootState) => {
  return state.data.driverActivities.byRouteId[routeId];
};

export const selectCompletedStopFromActivities =
  (stopId: string) => (state: RootState) => {
    return state.data.driverActivities.completedStops[stopId];
  };

export const selectArrivedStopFromActivities =
  (stopId: string) => (state: RootState) => {
    return state.data.driverActivities.arrivedStops[stopId];
  };

export const selectDepartedStopFromActivities =
  (stopId: string) => (state: RootState) => {
    return state.data.driverActivities.departedStops[stopId];
  };

export const selectIsStopCompletedFromActivities =
  (stopId: string | null | undefined) => (state: RootState) => {
    return stopId
      ? !!state.data.driverActivities.completedStops[stopId]
      : false;
  };
