import { useAppDispatch } from "../../redux-store";
import { useEffect } from "react";
import { setCurrentRouteFromURL } from "../../ducks/app/route-info/routing";
import Grid from "@mui/material/Grid";
import { Styles } from "../../styles/style-constants";
import DelayedRender from "../../Components/DelayedRender";
import { RoutePlanning } from "../../Components/dispatching-planning/route-planning/route-planning";

const RoutePageColumn = ({
  routeId,
}: {
  routeId: string | undefined;
}) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!routeId) {
      return;
    }
    dispatch(setCurrentRouteFromURL(routeId));
    return () => {
      dispatch(setCurrentRouteFromURL(null));
    };
  }, [routeId, dispatch]);
  return (
    <Grid item xs={4} sx={Styles.maxHeightContainer}>
      <Grid className="overflow-y-auto" container direction="column">
        {routeId && (
          <DelayedRender>
            <RoutePlanning routeId={routeId} />
          </DelayedRender>
        )}
      </Grid>
    </Grid>
  );
};

export default RoutePageColumn;
