import { RootState } from "../../../../redux-store";

export const selectSelectedRoute = (state: RootState) =>
  state.app.routeInfo.routing.selectedRouteId;

export const selectIsRouteSelected = (routeId: string) => (state: RootState) =>
  selectSelectedRoute(state) === routeId;

export const selectIsRouteOpen = (state: RootState) =>
  !!state.app.routeInfo.routing.selectedRouteId;

export const selectRouting = (state: RootState) =>
  state.app.routeInfo.routing.routing;

export const selectLineString = (state: RootState) =>
  selectRouting(state)?.linestring || null;

export const selectShowLargeMap = (state: RootState) =>
  state.app.routeInfo.routing.largeMap;
