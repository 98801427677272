import { RootState } from "../../../redux-store";
import { createSelector } from "@reduxjs/toolkit";
import { selectFeature } from "../../features/selectors";
import {
  selectArrivedStopFromActivities,
  selectCompletedStopFromActivities,
  selectDepartedStopFromActivities
} from "../driver-activities/selectors";

export const selectSessionsForRoute =
  (routeId: string) => (state: RootState) => {
    return state.data.driverSessions.byRouteId[routeId];
  };

export const selectCompletedStop = (stopId: string) => (state: RootState) => {
  return state.data.driverSessions.completedStops[stopId];
};

export const selectArrivedStop = (stopId: string) => (state: RootState) => {
  return state.data.driverSessions.arrivedStops[stopId];
};

export const selectDepartedStop = (stopId: string) => (state: RootState) => {
  return state.data.driverSessions.departedStops[stopId];
};

export const selectIsStopCompleted =
  (stopId: string | null | undefined) => (state: RootState) => {
    return stopId ? !!state.data.driverSessions.completedStops[stopId] : false;
  };

/**
 * It will return complete even if the stop only has departed, but not picked up / dropped off
 * @param stopId
 */
export const selectRouteStopStatus = (stopId: string) => createSelector(
  selectFeature("use-only-activities__dispatcher-driver-sessions"),
  selectCompletedStop(stopId),
  selectCompletedStopFromActivities(stopId),
  selectArrivedStop(stopId),
  selectArrivedStopFromActivities(stopId),
  selectDepartedStop(stopId),
  selectDepartedStopFromActivities(stopId),
  (useActivities, completedStop, completedStopFromActivities, arrivedStop, arrivedStopFromActivities, departedStop, departedStopFromActivities) => {
    const arrStop = useActivities ? arrivedStopFromActivities : arrivedStop;
    const depStop = useActivities ? departedStopFromActivities : departedStop;
    const compStop = useActivities ? completedStopFromActivities : completedStop;
    return compStop || depStop
      ? "complete"
      : arrStop
        ? "in-progress" : null;
  }
)
