import { HTMLAttributes, useEffect, useMemo, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  MenuItem,
} from "@mui/material";
import * as dialogActions from "../../../ducks/app/trailer-dialog";
import { FormData, parseNullable } from "../../../ducks/app/trailer-dialog";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import {
  trailerTypes,
  cargoTypes,
  trailerQualities,
  calcCbm,
} from "../../../helpers/types";
import InputAdornment from "@mui/material/InputAdornment";
import FeaturesList from "../common/features-autocomplete-list";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import FeatureToggle from "../../FeatureToggle";
import { useAppDispatch, useSelector } from "../../../redux-store";
import GenericAutocomplete from "../../GenericAutocomplete";
import { selectTrackerDevices } from "../../../ducks/app/fleet-management/selectors";
import * as dataActions from "../../../ducks/data/tracker-devices";
import { useFeature } from "../../../hooks";
import { TrackerDevice } from "dora-contracts";
import { Trailer } from "../../../ducks/data/trailers/types";
import { selectDepartments } from "../../../ducks/data/departments/selectors";
import { loadDepartments } from "../../../ducks/data/departments";
import trucksColors from "../../../helpers/trucks-colors";

type FormDataWithoutId = Omit<FormData, "id">;

interface TrailerVehicleDialogProps<T extends FormDataWithoutId> {
  trailers: Trailer[];
  initialData: T;
  onSubmit: (data: T) => void;
  submitting?: boolean;
  editingExisting?: boolean;
  title: string;
  saveButtonText: string;
}

interface TrailerDialogContentProps {
  trailerValues: FormData;
  setTrailerValues: (values: any) => void;
  editingExisting?: boolean;
  trailers: { number: string }[];
  availableTrackerDevices: TrackerDevice[];
}

export const TrailerDialogContent = (props: TrailerDialogContentProps) => {
  const { trailers, trailerValues, setTrailerValues, availableTrackerDevices } =
    props;
  const [error, setError] = useState(false);
  const { t } = useTranslation(["fleet", "types", "modals", "translation"]);
  const trackerDevicesEnabled = useFeature("tracker-devices");
  const departments = useSelector(selectDepartments);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadDepartments());
  }, [dispatch]);

  const handleChange = (e: any) => {
    setTrailerValues({
      ...trailerValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeAllowNull = (element: any) => {
    const { name, value } = element.target;
    setTrailerValues({
      ...trailerValues,
      [name]: value === "" ? null : value,
    });
  };

  const handleTypeChange = (type: string | null) => {
    const t = trailerTypes.find((x) => x.type === type);
    setTrailerValues({
      ...trailerValues,
      type,
      lm: t?.lm?.toString() || "",
      weight: t?.weight?.toString() || "",
      height: t?.height?.toString() || "",
      width: t?.width?.toString() || "",
      length: t?.length?.toString() || "",
      volume: calcCbm(t),
      departmentId: t?.departmentId || null,
      alias: t?.alias || null,
    });
  };

  const handleDepartmentIdChange = (departmentId: string | null) => {
    setTrailerValues({
      ...trailerValues,
      departmentId,
    });
  };

  const handleFuelTypeChange = (fuelType: string | null) => {
    setTrailerValues({
      ...trailerValues,
      fuelType,
    });
  };

  const handleColorChange = (color: string | null) => {
    setTrailerValues({
      ...trailerValues,
      color,
    });
  };

  const handleVehicleTypeChange = (vehicleType: string | null) => {
    setTrailerValues({
      ...trailerValues,
      vehicleType,
    });
  };

  const handleTrackerDeviceChange = (trackerDeviceId: string | null) => {
    setTrailerValues({
      ...trailerValues,
      trackerDeviceId,
    });
  };

  const trackerDeviceOptions = useMemo(() => {
    return availableTrackerDevices.map((trackerDevice: TrackerDevice) => ({
      id: trackerDevice.id,
      label: `${trackerDevice.deviceModelName}: ${trackerDevice.ident}`,
    }));
  }, [availableTrackerDevices]);

  const cargoTypeOptions = useMemo(() => {
    let cargoTypeOptions = cargoTypes.map(
      (id): { id: string; label: string } => ({
        id,
        label: t("types:cargoTypes.type", { context: id }),
      })
    );
    let trailerQualityOptions = trailerQualities.map((id) => ({
      id,
      label: t("types:trailerQualities.type", { context: id }),
    }));
    return cargoTypeOptions.concat(trailerQualityOptions);
  }, [t]);
  const types = useMemo(
    () =>
      trailerTypes
        .filter((x) => x.powered === trailerValues.powered)
        .map((i) => ({
          id: i.type,
          label: t(`types:trailerTypes.type`, {
            context: i.type,
          }),
        })),
    [trailerValues.powered, t]
  );
  const fuelTypes = (["DIESEL", "CNG", "LNG", "BEV"] as const).map((i) => ({
    id: i,
    label: t(`types:fuelTypes.${i}`),
  }));
  const vehicleTypes = (
    [
      "EURO 1",
      "EURO 2",
      "EURO 3",
      "EURO 4",
      "EURO 5",
      "EURO 6",
      "Electric",
      "Hydrogen",
      "Gas",
    ] as const
  ).map((i) => ({
    id: i,
    label: t(`types:vehicleTypes.${i}`),
  }));

  const departmentsOptions: { id: any; label: string }[] = useMemo(
    () => departments.map((d) => ({ id: d.id, label: d.name })),
    [departments]
  );

  const colorOptions: { id: any; label: string }[] = useMemo(
    () =>
      trucksColors.map((d) => ({
        id: d.id,
        label: d.description,
        color: d.color,
      })),
    []
  );
  const truckColorInputIndicator = useMemo(
    () =>
      trucksColors.find((tc) => tc.id === trailerValues.color)?.color ||
      "transparent",
    [trailerValues]
  );

  return (
    <DialogContent>
      <Grid container spacing={1}>
        <Grid item xs>
          <GenericAutocomplete
            label={t("modals:addNewTrailer.type")}
            required
            autoFocus
            options={types}
            value={trailerValues.type}
            onChange={handleTypeChange}
          />
        </Grid>
        <Grid item xs>
          <TextField
            value={trailerValues.number}
            disabled={props.editingExisting}
            onChange={(e) => {
              handleChange(e);
              trailers.find((trailer) => trailer.number === e.target.value)
                ? setError(true)
                : setError(false);
            }}
            name="number"
            label={t("modals:addNewTrailer.input.formFieldTrailerNumber")}
            error={error}
            helperText={
              error &&
              `${t(
                "modals:addNewTrailer.formValidation.trailerNumberAlreadyExists"
              )}`
            }
            type="text"
            required
          />
        </Grid>
        <Grid item xs>
          <TextField
            value={trailerValues.alias}
            onChange={(e) => {
              handleChange(e);
            }}
            name="alias"
            label={t("modals:addNewTrailer.input.alias")}
            type="text"
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField
            name="department"
            label={t("modals:addNewTrailer.input.formFieldDepartment")}
            type="text"
            value={trailerValues.department || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <GenericAutocomplete
            label={t("modals:addNewTrailer.input.formFieldDimension")}
            options={departmentsOptions}
            value={trailerValues.departmentId}
            onChange={handleDepartmentIdChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={5}>
          <FeaturesList
            label={t("modals:addNewTrailer.input.formFieldQualities")}
            qualityOptions={cargoTypeOptions}
            qualities={trailerValues.qualities || []}
            onChange={(selected: any) => {
              setTrailerValues({
                ...trailerValues,
                qualities: selected,
              });
            }}
          />
          <FeatureToggle feature="smartMatch">
            <FormHelperText>
              {t("modals:addNewTrailer.input.qualitiesImportantToSmartMatch")}
            </FormHelperText>
          </FeatureToggle>
        </Grid>
        <Grid item xs={4}>
          <TextField
            value={trailerValues.note || ""}
            onChange={handleChange}
            name="note"
            label={t("modals:addNewTrailer.input.formFieldNotes")}
            type="text"
            multiline
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            value={trailerValues.lm || ""}
            onChange={handleChange}
            name="lm"
            label={t("modals:addNewTrailer.input.formFieldLoadmeter")}
            type="number"
            required
          />
        </Grid>
      </Grid>
      <Typography m={1} variant="subtitle2">
        {t("modals:addNewTrailer.input.formFieldCapacity")}
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <TextField
            value={trailerValues.length || ""}
            onChange={handleChange}
            name="length"
            label={t("translation:measurements.length")}
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            value={trailerValues.width || ""}
            onChange={handleChange}
            name="width"
            label={t("translation:measurements.width")}
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            value={trailerValues.height || ""}
            onChange={handleChange}
            name="height"
            label={t("translation:measurements.height")}
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField
            value={trailerValues.volume || ""}
            onChange={handleChange}
            name="volume"
            label={t("translation:measurements.volume")}
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  m
                  <span
                    style={{
                      fontSize: "0.75em",
                      verticalAlign: "super",
                    }}
                  >
                    3
                  </span>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={trailerValues.weight || ""}
            onChange={handleChange}
            name="weight"
            label={t("translation:measurements.weight")}
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
            required
          />
        </Grid>
      </Grid>
      {trailerValues.powered ? (
        <>
          <Typography m={1} variant="subtitle2">
            {t("modals:addNewTrailer.input.engineFuelSpecs")}
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <GenericAutocomplete
                label={t("translation:measurements.fuelType")}
                options={fuelTypes}
                value={trailerValues.fuelType}
                onChange={handleFuelTypeChange}
              />
            </Grid>
            <Grid item xs={6}>
              <GenericAutocomplete
                label={t("translation:measurements.vehicle")}
                required
                options={vehicleTypes}
                value={trailerValues.vehicleType}
                onChange={handleVehicleTypeChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                type="number"
                name="deviationPercentage"
                value={trailerValues.deviationPercentage}
                onChange={handleChangeAllowNull}
                label={t("translation:measurements.deviation") + " (%)"}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="number"
                name="kmPerLiter"
                value={trailerValues.kmPerLiter}
                onChange={handleChange}
                label={t("translation:measurements.kmPerLiter")}
              />
            </Grid>
          </Grid>
        </>
      ) : null}
      {trackerDevicesEnabled ? (
        <>
          <Typography m={1} variant="subtitle2">
            {t("modals:addNewTrailer.input.tracking")}
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <GenericAutocomplete
                label={t("modals:addNewTrailer.input.trackerDevice")}
                options={trackerDeviceOptions}
                value={trailerValues.trackerDeviceId}
                onChange={handleTrackerDeviceChange}
              />
            </Grid>
            <Grid item xs={6}>
              <GenericAutocomplete
                label={t("modals:addNewTrailer.input.color")}
                options={colorOptions}
                value={trailerValues.color}
                onChange={handleColorChange}
                renderInput={(params) => (
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        width: 30,
                        height: 20,
                        backgroundColor: truckColorInputIndicator,
                        position: "absolute",
                        top: 18,
                        left: 12,
                        borderRadius: 4,
                      }}
                    ></div>
                    <TextField
                      {...params}
                      label={t("modals:addNewTrailer.input.color")}
                      sx={{ input: { textIndent: 37 } }}
                    ></TextField>
                  </div>
                )}
                renderOption={(
                  pr: HTMLAttributes<HTMLLIElement>,
                  option: any
                ) => (
                  <MenuItem {...pr} key={option.value}>
                    <div
                      style={{ backgroundColor: option.color }}
                      className="cargo-tag__color"
                    ></div>{" "}
                    {option.label}
                  </MenuItem>
                )}
              />
            </Grid>
          </Grid>
        </>
      ) : null}
    </DialogContent>
  );
};
export default function TrailerVehicleDialog<T extends FormDataWithoutId>(
  props: TrailerVehicleDialogProps<T>
) {
  const [trailerValues, setTrailerValues] = useState(props.initialData);
  const dispatch = useAppDispatch();
  const onCancel = () => {
    dispatch(dialogActions.close());
  };
  const { t } = useTranslation("modals");
  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    props.onSubmit({
      ...trailerValues,
      volume: trailerValues.volume || null,
    });
  };

  const trailers = props.trailers;
  const usedTrackerDevices = trailers
    .filter((t: Trailer) => t.trackerDeviceId)
    .map((t: Trailer) => t.trackerDeviceId);

  const trackerDevices = useSelector(selectTrackerDevices);
  useEffect(() => {
    dispatch(dataActions.loadTrackerDevices());
  }, [dispatch]);

  const availableTrackerDevices = Object.values(trackerDevices).filter(
    (trackerDevice) =>
      !usedTrackerDevices.includes(trackerDevice.id) ||
      trailerValues.trackerDeviceId === trackerDevice.id
  );

  return (
    <Dialog open={true}>
      <form onSubmit={onSubmit}>
        <DialogTitle> {props.title} </DialogTitle>
        <TrailerDialogContent
          editingExisting={props.editingExisting}
          trailers={props.trailers}
          trailerValues={{
            ...parseNullable(trailerValues),
            type: trailerValues.type,
            fuelType: trailerValues.fuelType,
            vehicleType: trailerValues.vehicleType,
            deviationPercentage: trailerValues.deviationPercentage,
            kmPerLiter: trailerValues.kmPerLiter,
          }}
          setTrailerValues={setTrailerValues}
          availableTrackerDevices={availableTrackerDevices}
        />
        <DialogActions>
          <Button onClick={onCancel}>
            {t("addNewTrailer.actions.buttonCancel")}
          </Button>
          <Button type="submit" disabled={props.submitting}>
            {props.saveButtonText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
