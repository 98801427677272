/* eslint-disable no-unused-vars */
import { Routes, Route, Navigate } from "react-router-dom";
import UpcomingPage from "./UpcomingPage/UpcomingPage";
import VehiclesPage from "./FleetManagementPage/VehiclesTab/VehiclesPage";
import DriversPage from "./FleetManagementPage/DriversTab/DriversPage";
import AnalyticsPage from "./AnalyticsPage/Loader";
import FeatureToggle from "../Components/FeatureToggle";
import SettingsPage from "./SettingsPage";
import UserAccountPage from "./UserAccountPage";
import ConsolidatedInvoicingPage from "./ConsolidatedInvoicePage";
import OutgoingInvoicePage from "./OutgoingInvoicePage";
import DispatchPage from "./DispatchPage/loader";
import EmissionsPage from "./EmissionsPage/Loader";
import CargosReportPage from "./CargosReportPage/Loader";
import TrackerDevicesPage from "./FleetManagementPage/TrackerDevicesTab/TrackerDevicesPage";
// import TrackerDevicesPage from "../Components/fleet-management/TrackerDevicesPage";
import VehiclePage from "./VehiclePage";
import OnboardingVideosPage from "./OnboardingVideosPage";
import LiveViewPages from "./LiveViewPage";
import { useFeature } from "../hooks";
import LightVersionSingleRoute from "./LightVersionSingleRoute/LightVersionSingleRoute";

const AuthenticatedRoutes = () => {
  const liteVersion = useFeature("lite-version");

  return (
    <Routes>
      {!liteVersion && (
        <>
          <Route path="/dispatching/*" element={<DispatchPage />} />
          <Route
            path="/finance/outgoing-invoices/:id"
            element={<OutgoingInvoicePage />}
          />
          <Route path="/fleet/vehicles/:vehicleId" element={<VehiclePage />} />
          <Route path="/upcoming" element={<UpcomingPage />} />
          <Route path="/live-view/*" element={<LiveViewPages />} />
          <Route path="/user-account" element={<UserAccountPage />} />
          <Route path="/drivers" element={<DriversPage />} />
          <Route path="/tracker-devices" element={<TrackerDevicesPage />} />
          <Route
            path="/consolidated-invoicing"
            element={<ConsolidatedInvoicingPage />}
          />
          <Route
            path="/analytics"
            element={
              <FeatureToggle feature="analytics">
                <AnalyticsPage />
              </FeatureToggle>
            }
          />
          <Route path="*" element={<Navigate to="/dispatching" />} />
        </>
      )}
      {liteVersion && (
        <>
          <Route path="*" element={<Navigate to="/add" />} />
          <Route path="/add" element={<LightVersionSingleRoute />} />
        </>
      )}
      <Route path="/settings/*" element={<SettingsPage />} />
      <Route path="/fleet" element={<VehiclesPage />} />
      <Route path="/help-and-support" element={<OnboardingVideosPage />} />

      <Route path="/emissions" element={<EmissionsPage />} />
      <Route path="/cargos-report" element={<CargosReportPage />} />
    </Routes>
  );
};

export default AuthenticatedRoutes;
