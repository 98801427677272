import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Menu,
  ListItemText,
  ListItemIcon,
  MenuItem,
  IconButton,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import IntegrationInstructionsOutlinedIcon from "@mui/icons-material/IntegrationInstructionsOutlined";
import PhonelinkSetupOutlinedIcon from "@mui/icons-material/PhonelinkSetupOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import { useTranslation } from "react-i18next";
import { MuiTooltip } from "./nav-styles";
import { useFeature } from "../../hooks";

const SettingsMenu = () => {
  const { t } = useTranslation("settings");
  const [open, setOpen] = useState<null | HTMLElement>(null);
  const schedulingUIEnabled = useFeature("scheduling-ui");
  const liteVersion = useFeature("lite-version");

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };

  return (
    <>
      <MuiTooltip title={t("menuHeading")} placement="bottom">
        {/* <Button
          id="settings-menu"
          size="large"
          startIcon={<SettingsIcon />}
          onClick={handleOpenMenu}
          sx={{ ".MuiSvgIcon-root": { color: "var(--warning-500)" } }}
        /> */}
        <IconButton
          sx={{ ".MuiSvgIcon-root": { color: "var(--warning-500)" } }}
          onClick={handleOpenMenu}
        >
          <SettingsIcon />
        </IconButton>
      </MuiTooltip>
      <Menu
        anchorEl={open}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        keepMounted
        open={Boolean(open)}
        onClose={handleCloseMenu}
        sx={{
          cursor: "pointer",
          "& a": { py: "0.5rem" },
          "& span": { fontSize: "14px" },
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            width: 200,
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 15,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        <MenuItem
          component={RouterLink}
          to="/settings/customers"
          onClick={() => handleCloseMenu()}
        >
          <ListItemIcon>
            <SettingsOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("customersConfiguration.pageHeading")}</ListItemText>
        </MenuItem>
        {!liteVersion && (
          <MenuItem
            component={RouterLink}
            to="/settings/finance"
            onClick={() => handleCloseMenu()}
          >
            <ListItemIcon>
              <ReceiptLongOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("financePageHeading")}</ListItemText>
          </MenuItem>
        )}
        {!liteVersion && (
          <MenuItem
            component={RouterLink}
            to="/settings/users"
            onClick={() => handleCloseMenu()}
          >
            <ListItemIcon>
              <PeopleAltOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {t("dispatchersConfiguration.pageHeading")}
            </ListItemText>
          </MenuItem>
        )}
        {!liteVersion && (
          <MenuItem
            component={RouterLink}
            to="/settings/templates"
            onClick={() => handleCloseMenu()}
          >
            <ListItemIcon>
              <DescriptionOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("templatesHeading")} </ListItemText>
          </MenuItem>
        )}
        {!liteVersion && (
          <MenuItem
            component={RouterLink}
            to="/settings/setup"
            onClick={() => handleCloseMenu()}
          >
            <ListItemIcon>
              <PhonelinkSetupOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("setupSettings.pageHeading")}</ListItemText>
          </MenuItem>
        )}

        <MenuItem
          component={RouterLink}
          to="/settings/integrations"
          onClick={() => handleCloseMenu()}
        >
          <ListItemIcon>
            <IntegrationInstructionsOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("integrations.pageHeading")}</ListItemText>
        </MenuItem>
        {!liteVersion && schedulingUIEnabled && (
          <MenuItem
            component={RouterLink}
            to="/settings/scheduling"
            onClick={() => handleCloseMenu()}
          >
            <ListItemIcon>
              <DateRangeOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("scheduling.pageHeading")}</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default SettingsMenu;
