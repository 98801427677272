import React, { FC } from "react";
// import { formatBasicStringDate } from "../../../helpers/date-helper";
// import { Cargo } from "../../../models/cargo";
import { Matching } from "../../../models/matching";
// import { Styles } from "../../../styles/style-constants";
// import {
// firstPickup,
// getLocationText,
// isPickup,
// lastDropoff,
// } from "../common/cargo-combine-util";
// import { useTranslation } from "react-i18next";
import { CargoView } from "../../../ducks/data/cargo-views/types";

interface Props {
  cargoInfo: CargoView;
  matching?: Matching;
}

export const SendEmailDirectly: FC<Props> = React.memo((props) => {
  return null;
  /*
    const { t } = useTranslation(["dispatching"]);
    // Get relevant cargos
    const firstCargo = useMemo(
        () => firstPickup(props.cargoInfo),
        [props.cargoInfo]
    );
    const lastCargo = useMemo(
        () => lastDropoff(props.cargoInfo),
        [props.cargoInfo]
    );

    // Get from-to location
    const locationInfo = useMemo(() => {
        if (firstCargo && lastCargo) {
            return (
                (getLocationText(firstCargo, false) ||
                    `${t("dispatching:StartPosition")}`) +
                " " +
                (firstCargo.city ?? "") +
                " - " +
                (getLocationText(lastCargo, false) ||
                    `${t("dispatching:Destination")}`) +
                " " +
                (lastCargo.city ?? "")
            );
        }
        return "";
    }, [lastCargo, firstCargo, t]);

    // total ldm
    const ldm = props.cargoInfo.cargos
        ?.filter(isPickup)
        .reduce((a, b) => a + +b.lm, 0);

    // Format the email body
    const formatMail = () => {
        if (firstCargo && lastCargo && props.cargoInfo.cargos) {
            // Basic cargo info details
            let formattetBody = `${locationInfo}
        ${formatBasicStringDate(firstCargo.date)} ${
                firstCargo.fix_time ? "(" + firstCargo.fix_time + ")" : ""
            } - ${formatBasicStringDate(lastCargo.date)} ${
                lastCargo.fix_time ? "(" + lastCargo.fix_time + ")" : ""
            } \n
        `;

            // Format each cargo with details
            [...props.cargoInfo.cargos].forEach((cargo) => {
                formattetBody += `${props.cargoInfo.type}, ${cargo.lm} ldm. (${
                    cargo.height ?? 0
                }x${cargo.width ?? 0}x${cargo.length ?? 0}), ${
                    cargo.temperature ?? ""
                } °C 
${t("dispatching:Notes")}: ${cargo.note ?? ""},
${t("dispatching:Qualities")}: ${props.cargoInfo.qualities.join(", ")}
${t("dispatching:TrailerDemands")}: ${props.cargoInfo.requirements.join(
                    ", "
                )} ${getLocationText(cargo, false)} ${cargo.city ?? ""} \n\n`;
            });

            var mailToLink = `mailto:${
                props.matching && props.matching.email
            }?`;
            var mailContent = `Subject=Vedr. ${locationInfo}, ${ldm} ldm&`;
            mailContent += "body=" + encodeURIComponent(formattetBody);

            return mailToLink + mailContent;
        }

        return "";
    };

    return (
        <div id="ex">
            <a
                style={Styles.anchortagStyling}
                title={t("dispatching:SendOnEmail")}
                href={formatMail()}
            >
                {t("dispatching:SendOnEmail")}
            </a>
        </div>
    );
    */
});
