import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux-store";
import { useTranslation } from "react-i18next";

export const MapDistance = React.memo(() => {
  const { t } = useTranslation(["dispatching", "translation"]);
  const originalDistance = useSelector(
    (state: RootState) => state.smartMatch.originalDistance
  );
  const newDistance = useSelector(
    (state: RootState) => state.smartMatch.newDistance
  );

  return (
    <Grid container direction="column" alignItems="flex-end" spacing={2}>
      <Grid item>
        <Typography whiteSpace="nowrap" variant="body2">
          {t("dispatching:openRouteView.distance")}:{" "}
          {(originalDistance &&
            originalDistance.toLocaleString() +
              ` ${t("translation:measurements.abbrev.kilometer")}`) ||
            ""}
        </Typography>
      </Grid>
      <Grid item>
        <Typography whiteSpace="nowrap" variant="body2">
          {t("dispatching:openRouteView.extra")}:{" "}
          {(newDistance &&
            (newDistance - (originalDistance || 0)).toLocaleString() +
              ` ${t("translation:measurements.abbrev.kilometer")}`) ||
            ""}
        </Typography>
      </Grid>
    </Grid>
  );
});
