import { FC } from "react";
import OpenLoadTrailerIcon from "../../assets/icons/aaben-lad.svg?react";
import OtherTrailerIcon from "../../assets/icons/andet.svg?react";
import AutoTrailerIcon from "../../assets/icons/auto-trailer.svg?react";
import BlockTrailerIcon from "../../assets/icons/blokvogn.svg?react";
import BoxTrailerIcon from "../../assets/icons/box.svg?react";
import BulkTrailerIcon from "../../assets/icons/bulk.svg?react";
import ContainerCbassisTrailerIcon from "../../assets/icons/container-chassis.svg?react";
import ContainerTrailerIcon from "../../assets/icons/container.svg?react";
import DumpTrailerIcon from "../../assets/icons/dumperlad.svg?react";
import FrontTrailerIcon from "../../assets/icons/forvogn.svg?react";
import FronHangerTrailerIcon from "../../assets/icons/forvognhaenger.svg?react";
import FrigoTrailerIcon from "../../assets/icons/frigo.svg?react";
import JumboTrailerIcon from "../../assets/icons/jumbo.svg?react";
import MegaTrailerIcon from "../../assets/icons/mega.svg?react";
import PTrailerIcon from "../../assets/icons/p-trailer.svg?react";
import TankTrailerIcon from "../../assets/icons/tank.svg?react";
import TermoTrailerIcon from "../../assets/icons/termo.svg?react";
import GoodsTrailerIcon from "../../assets/icons/varevogn.svg?react";
import BetonkanonTrailerIcon from "../../assets/icons/betonkanon.svg?react";
import { useSelector } from "react-redux";
import { selectTrailerType } from "../../ducks/data/trailers/selectors";

const iconProps = {
  fill: "rgba(255, 255, 255, 0)",
  // stroke: "rgba(0, 0, 0, 0.8)",
  stroke: "rgb(145, 145, 145)",
  strokeWidth: "12.6",
};

// const getIcon = (type: string): React.ComponentType<{fill: string | null, stroke: string | null, strokeWidth: string | null}> => {
const getIconComponent = (
  type: string
): React.ComponentType<{
  fill?: string;
  stroke?: string;
  strokeWidth?: string | number;
}> => {
  switch (type) {
    case "FRIGO":
      return FrigoTrailerIcon;
    case "CONCRERTE_CANNON":
      return BetonkanonTrailerIcon;
    case "BULK":
      return BulkTrailerIcon;
    case "AUTO_TRAILER":
      return AutoTrailerIcon;
    case "CURTAIN_SIDE":
      return PTrailerIcon;
    case "BOX":
      return BoxTrailerIcon;
    case "BLOCK_WAGON":
      return BlockTrailerIcon;
    case "FLATBED":
      return OpenLoadTrailerIcon;
    case "CONTAINER_CHASSIS":
      return ContainerCbassisTrailerIcon;
    case "CONTAINER":
      return ContainerTrailerIcon;
    case "CAB_OVER":
      return FrontTrailerIcon;
    case "CAB_OVER_TRAILER":
      return FronHangerTrailerIcon;
    case "JUMBO":
      return JumboTrailerIcon;
    case "MEGA":
      return MegaTrailerIcon;
    case "VAN":
      return GoodsTrailerIcon;
    case "TANKER":
      return TankTrailerIcon;
    case "THERMO":
      return TermoTrailerIcon;
    case "DUMPERLOAD":
      return DumpTrailerIcon;
    // Missing icon for ANIMAL_TRANSPORT
    default:
      return OtherTrailerIcon;
  }
};

interface Props {
  trailerId: string | null;
}

export const TrailerTypeAvatar: FC<Props> = (props) => {
  const trailerType = useSelector(selectTrailerType(props.trailerId));
  if (!trailerType) return null;
  const Icon = getIconComponent(trailerType);
  return <Icon {...iconProps} />;
};
