export const Styles = {
  maxHeightContainer: {
    maxHeight: "100%",
    display: "grid",
    gridTemplateRows: "auto auto 1fr",
  } as React.CSSProperties,

  maxHeight: {
    maxHeight: "100%",
  } as React.CSSProperties,

  maxHeightMinWidth: {
    maxHeight: "100%",
    minWidth: "100%",
  } as React.CSSProperties,

  overflowY: {
    overflowY: "auto",
  } as React.CSSProperties,

  maxHeightContainerNoFlex1: {
    maxHeight: "100%",
    display: "flex",
    flexDirection: "column",
  } as React.CSSProperties,

  anchortagStyling: {
    color: "#6487C3",
    textDecoration: "none",
  } as React.CSSProperties,
};
