import * as React from "react";
import {
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { selectDrivers } from "../../../ducks/data/drivers/selectors";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DriversList = ({
  routeId,
  onDriverClick,
  routeDrivers,
}: {
  routeId: string;
  onDriverClick: (driverId: string, checked: boolean) => void;
  routeDrivers: string[] | null;
}) => {
  const drivers = useSelector(selectDrivers);
  const { t } = useTranslation("dispatching");
  return (
    <>
      {drivers
        .filter((x) => x.active)
        .map((driver) => {
          const labelId = `checkbox-list-label-${routeId}-${driver.id}`;
          const checked = routeDrivers?.includes(driver.id);
          return (
            <MenuItem
              key={driver.id}
              onClick={() => onDriverClick(driver.id, !checked)}
              sx={{ p: 0.6 }}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={checked}
                inputProps={{ "aria-labelledby": labelId }}
              />
              <ListItemText
                id={labelId}
                primary={`${driver.firstName} ${driver.lastName}`}
                secondary={`${t("routeCard.driverNumber")} ${driver.number}`} // at some point a driver should be connected to a department, and this could potentially be shown here
              />
            </MenuItem>
          );
        })}
    </>
  );
};

const AddDrivers = (props: {
  routeId: string;
  onDriverClick: (driverId: string, checked: boolean) => void;
  routeDrivers: string[] | null;
}) => {
  const { routeId, routeDrivers } = props;
  const [open, setOpen] = React.useState(false);
  const ref = React.useRef<HTMLButtonElement | null>(null);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onDriverClick = (driverId: string, checked: boolean) => {
    props.onDriverClick(driverId, checked);
    setOpen(false);
  };

  const { t } = useTranslation("dispatching");
  return (
    <div>
      <Tooltip title={t("routeCard.addDriver")}>
        <IconButton
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          ref={ref}
          sx={{ padding: 0 }}
        >
          <PersonAddAlt1Icon fontSize="small" />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={ref.current}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "24ch",
          },
        }}
      >
        <DriversList
          onDriverClick={onDriverClick}
          routeId={routeId}
          routeDrivers={routeDrivers}
        />
      </Menu>
    </div>
  );
};

export default AddDrivers;
