import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../redux-store";
// TODO: Get rid of, app logic layer shall not reference UI component code.
import { combineCargo } from "../../Components/dispatching-planning/common/cargo-combine-util";

export const matchingsSelector = (routeId: string) =>
  createSelector(
    (state: RootState) => state.smartMatch.matchings,
    (matchings) =>
      matchings.filter((matching) => matching.matched_route_id === routeId)
  );

export const cargoMatchingsSelector = (cargoInfoId: string) =>
  createSelector(
    (state: RootState) => state.smartMatch.matchings,
    (matchings) =>
      matchings.filter((matching) => matching.cargo_info_id === cargoInfoId)
  );

export const smartMatchSelector = (routeId: string) =>
  createSelector([(state: RootState) => state.smartMatch.cargos], (cargos) =>
    combineCargo(
      cargos.filter(
        (cargo) => cargo.matched_route_id === routeId && !cargo.route_id
      )
    )
  );
