import { useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "../../axios";
import Container from "@mui/material/Container";
import ContentStyle from "./ContentStyle";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import { Trans, useTranslation } from "react-i18next";
import { UAParser } from "ua-parser-js";

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const EnterResetCodePage = () => {
  const query = useQuery();
  const [form, setForm] = useState({
    email: query.get("email") || "",
    code: query.get("code") || "",
    password: "",
  });
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { t } = useTranslation("auth");

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setForm((form) => ({ ...form, [e.target.name]: e.target.value }));
  };

  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/auth/submit-new-password", form)
      .then(({ data }) => {
        if (data.success) {
          setSuccess(true);
          const parser = new UAParser(window.navigator.userAgent); // you need to pass the user-agent for nodejs
          const parserResults = parser.getResult();
          window.location.replace(
            parserResults.device.type === "mobile" ? "/live-view" : "/"
          );
        } else {
          setError(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container>
      <ContentStyle>
        <h1>{t("submitCodePage.heading")}</h1>
        <p>{t("submitCodePage.paragraph1")}</p>
        <p>
          <Trans t={t} i18nKey="submitCodePage.paragraph2">
            Koden er gyldig i 10 minutter
            <Link to="/welcome/reset">tryk her</Link>
          </Trans>
        </p>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="email"
              type="email"
              label={t("submitCodePage.emailLabel")}
              value={form.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              autoFocus={!query.get("code")}
              name="code"
              label={t("submitCodePage.codeLabel")}
              value={form.code}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="password"
              type="password"
              label={t("submitCodePage.passwordLabel")}
              autoFocus={!!query.get("code")}
              value={form.password}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={loading}
              fullWidth
              type="submit"
              variant="contained"
              onClick={handleClick}
            >
              {t("submitCodePage.submitButtonText")}
            </LoadingButton>
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Alert severity="error">
                {t("submitCodePage.invalidCodeError")}
              </Alert>
            </Grid>
          )}
          {success && (
            <Grid item xs={12}>
              <Alert severity="success">
                <Trans t={t} i18nKey="submitCodePage.passwordResetMessage">
                  Dit password er indstillet.
                  <Link to="/welcome/login">Gå til Login</Link>
                </Trans>
              </Alert>
            </Grid>
          )}
        </Grid>
      </ContentStyle>
    </Container>
  );
};

export default EnterResetCodePage;
