// import type from "@here/maps-api-for-javascript"; // eslint-disable-line
import * as actions from "../../../ducks/data/routing";
import * as selectors from "../../../ducks/data/routing/selectors";
import { useMemo, useEffect } from "react";
import { InnerMap } from "./map";
import { useAppDispatch } from "../../../redux-store";
import { useSelector } from "react-redux";
import { MapMarkerEnd, MapMarkerStart } from "./map-marker";

const Routing = ({ routeId }: { routeId: string }) => {
  const dispatch = useAppDispatch();
  const routing = useSelector(selectors.selectRouting());
  const data = useMemo(
    () =>
      routing && routing.sections.length
        ? {
            linestring: new H.geo.MultiLineString(
              routing.sections.map(H.geo.LineString.fromFlexiblePolyline)
            ),
            points: routing.points.map(([lng, lat], i) => {
              if (i === 0) {
                return new H.map.Marker(
                  { lat, lng },
                  {
                    icon: new H.map.Icon(MapMarkerStart),
                    data: "",
                  }
                );
              }
              if (i === routing.points.length - 1) {
                return new H.map.Marker(
                  { lat, lng },
                  {
                    icon: new H.map.Icon(MapMarkerEnd),
                    data: "",
                  }
                );
              }
              return new H.map.Marker({ lat, lng });
            }),
          }
        : null,
    [routing]
  );

  useEffect(() => {
    dispatch(actions.loadRouting(routeId));
  }, [dispatch, routeId]);

  return <InnerMap lineStrings={data?.linestring} markers={data?.points} />;
};

export default Routing;

/*
 *
Uncaught (in promise) InvalidArgumentError: H.geo.LineString (Argument #0 57.70023,11.96967,57.70004,11.96921,57.7001,11.96912,57.7002,11.96898,57.70027,11.96886,57.70038,11.96863,57.70044,11.96884,57.7006,11.96923,57.70085,11.96985,57.70091,11.96999,57.70125,11.9708,57.70163,11.97173,57.70177,11.97207,57.70195,11.97251,57.70203,11.97271,57.7023,11.97338,57.70247,11.97379,57.70255,11.97401,57.70267,11.97435,57.70276,11.97458,57.70293,11.975,57.70302,11.97524,57.70315,11.97555,57.7033,11.9759,57.70343,11.97621,57.70368,11.97676,57.70393,11.97735,57.7042,11.97799,57.70426,11.97813,57.70432,11.97827,57.70437,11.97836,57.70443,11.97844,57.7045,11.9785,57.70456,11.97853,57.70463,11.97855,57.70468,11.97855,57.70511,11.97853,57.70536,11.97854,57.70585,11.97855,57.70605,11.97853,57.7064,11.97858,57.70655,11.97859,57.70662,11.9786,57.70672,11.97861,57.7068,11.97862,57.70691,11.97864,57.70698,11.97867,57.70703,11.9787,57.70708,11.97875,57.70712,11.97881,57.70715,11.97887,57.70715,11.979,57.70715,11.97918,57.70715,11.97973,57.70715,11.9801…
    D mapsjs-core.js:42
    ah mapsjs-core.js:132
    L mapsjs-core.js:127
    Routing Routing.tsx:15
    promise callback*Routing/< Routing.tsx:12
    React 15
    workLoop scheduler.development.js:266
    flushWork scheduler.development.js:239
    performWorkUntilDeadline scheduler.development.js:533
    js scheduler.development.js:571
    js scheduler.development.js:633
    Webpack 19
mapsjs-core.js:42:779
    ah mapsjs-core.js:132
    L mapsjs-core.js:127
    Routing Routing.tsx:15
    (Async: promise callback)
    Routing Routing.tsx:12
    React 10
    performSyncWorkOnRoot self-hosted:1406
    React 5
    performConcurrentWorkOnRoot self-hosted:1406
    workLoop scheduler.development.js:266
    flushWork scheduler.development.js:239
    performWorkUntilDeadline scheduler.development.js:533
    (Async: EventHandlerNonNull)
    js scheduler.development.js:571
    js scheduler.development.js:633
    Webpack 19

 */
