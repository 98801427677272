import { removeRouteTrailer, updateRouteTrailer } from "../../../ducks/routes";
import { useSelector } from "react-redux";
import { selectRouteViewModel } from "../../../ducks/data/route-views/selectors";
import { useAppDispatch } from "../../../redux-store";
import { selectTrailers } from "../../../ducks/data/trailers/selectors";
import TrailerListAutocomplete from "./trailer-list-autocomplete";

const TrailerListAutocompleteForRoute = (props: { routeId: string }) => {
  const { routeId } = props;
  const dispatch = useAppDispatch();
  const route = useSelector(selectRouteViewModel(routeId));
  const trailers = useSelector(selectTrailers);

  const handleTrailerChange = (event: { id: string } | null) => {
    if (route) {
      event
        ? dispatch(
            updateRouteTrailer({
              id: route.id,
              trailer_id: event.id,
            })
          )
        : dispatch(
            removeRouteTrailer({
              id: route.id,
            })
          );
    }
  };

  if (route?.trailerId && !trailers?.length) {
    // If the route has a trailer, but trailers are not loaded, don't render this yet.
    return null;
  }

  return (
    <TrailerListAutocomplete
      routeTrailerId={route?.trailerId || null}
      handleTrailerChange={handleTrailerChange}
    />
  );
};

export default TrailerListAutocompleteForRoute;
