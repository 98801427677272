import { useCallback } from "react";
import { useStableNavigate } from "../Components/StableNavigateContext";

const useNavigateToRoute = () => {
  const navigate = useStableNavigate();
  return useCallback(
    (routeId: string | null) => {
      const newPath = routeId
        ? `/dispatching/routes/${routeId}`
        : "/dispatching";
      navigate(newPath);
    },
    [navigate]
  );
};

export default useNavigateToRoute;
