import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";

export const NewNoteDot = (props: {
  className?: string;
  note?: string | null;
}) => {
  const { note } = props;
  return (
    (note && (
      <Tooltip title={note} placement="right">
        <Box
          className={props.className}
          display="inline"
          sx={{ cursor: "default" }}
          bgcolor="primary.main"
          height="12px"
          width="12px"
          borderRadius="100%"
        >
          &nbsp;
        </Box>
      </Tooltip>
    )) ||
    null
  );
};
const NoteDot = (props: { className?: string; note?: string | null }) => {
  const { note } = props;
  return (
    (note && (
      <Tooltip title={note} placement="right">
        <Box
          sx={{ cursor: "default" }}
          bgcolor="primary.main"
          height="10px"
          width="10px"
          borderRadius="100%"
          position="absolute"
          top={0}
          right={-12}
        >
          &nbsp;
        </Box>
      </Tooltip>
    )) ||
    null
  );
};

export default NoteDot;
