import * as React from "react";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { DrawerHeader, Drawer, MuiTooltip } from "./nav-styles";
import TopBar from "./topbar";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import MoneyIcon from "@mui/icons-material/Money";
import Co2OutlinedIcon from "@mui/icons-material/Co2Outlined";
import FeatureToggle from "../FeatureToggle";
import { useTranslation } from "react-i18next";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { useFeature } from "../../hooks";
import "./navbar.scss";
import { SummarizeOutlined } from "@mui/icons-material";

const MenuItem = (props: {
  id?: string;
  text: string;
  icon: React.ReactNode;
  link: string;
  open: boolean;
}) => (
  <MuiTooltip
    disableHoverListener={props.open}
    title={props.text}
    placement="right"
  >
    <ListItem button component={Link} to={props.link} id={props.id}>
      {props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}
      <ListItemText primary={props.text} />
    </ListItem>
  </MuiTooltip>
);

export default function NavBar() {
  const [open, setOpen] = React.useState(false);
  const toggleDrawerOpen = () => setOpen((x) => !x);
  const { t } = useTranslation(["translation", "dispatching", "navigation"]);
  const liveViewEnabled = useFeature("live-view");
  const cargoReportEnabled = useFeature("cargos-report");
  const liteVersion = useFeature("lite-version");

  return (
    <>
      <TopBar handleDrawerOpen={toggleDrawerOpen} open={open} />

      <Drawer
        variant="permanent"
        open={open}
        style={{ justifyContent: "space-between" }}
        className="left-drawer"
      >
        <DrawerHeader>
          <IconButton onClick={toggleDrawerOpen}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {!liteVersion && (
            <MenuItem
              text={t("navigation:navbar.menuItemUpcoming")}
              link="/upcoming"
              icon={<EventOutlinedIcon />}
              open={open}
            />
          )}
          {!liteVersion && (
            <MenuItem
              text={t("navigation:navbar.menuItemDispatching")}
              id="dispatch-page-menu"
              link="/dispatching"
              icon={<DashboardOutlinedIcon />}
              open={open}
            />
          )}
          {liteVersion && (
            <MenuItem
              // text={t("navigation:navbar.menuItemDispatching")}
              text="Add"
              id="add-route-page-menu"
              link="/add-route"
              icon={<DashboardOutlinedIcon />}
              open={open}
            />
          )}
          {!liteVersion && liveViewEnabled && (
            <MenuItem
              text={t("navigation:navbar.menuItemLiveView")}
              link="/live-view"
              icon={<MapOutlinedIcon />}
              open={open}
            />
          )}
          <MenuItem
            text={t("navigation:navbar.menuItemFleet")}
            link="/fleet"
            icon={<FormatListBulletedOutlinedIcon />}
            open={open}
          />
          {!liteVersion && (
            <MenuItem
              text={t("navigation:navbar.menuItemConsolidatedInvoicing")}
              link="/consolidated-invoicing"
              icon={<MoneyIcon />}
              open={open}
            />
          )}
          {!liteVersion && (
            <FeatureToggle feature="analytics">
              <MenuItem
                text={t("navigation:navbar.menuItemAnalytics")}
                link="/analytics"
                icon={<BarChartOutlinedIcon />}
                open={open}
              />
            </FeatureToggle>
          )}
          <MenuItem
            text={t("navigation:navbar.menuItemEmissions")}
            link="/emissions"
            icon={
              <Co2OutlinedIcon />
              // <FontAwesomeIcon
              //     style={{ padding: "4px" }}
              //     icon={faSeedling}
              // />
            }
            open={open}
          />
          {!liteVersion && cargoReportEnabled && (
            <MenuItem
              text={t("navigation:navbar.menuItemCargoReport")}
              link="/cargos-report"
              icon={<SummarizeOutlined />}
              open={open}
            />
          )}
        </List>
      </Drawer>
    </>
  );
}
