import { useState, useCallback } from "react";
import axios, { isAxiosError } from "../axios";
import { useNavigate } from "react-router-dom";

export function useRegistration() {
  const [error, setError] = useState<any>();
  const navigate = useNavigate();
  const handleSubmit = useCallback(
    (vals: any) => {
      axios
        .post("/registration", vals)
        .then(({ data }) => {
          data.success ? navigate("/") : setError(true);
        })
        .catch((err) => {
          if (isAxiosError(err)) {
            setError(err.response?.data || true);
          } else {
            console.log("Error registering user", err);
            setError(true);
          }
        });
    },
    [navigate]
  );
  return [error, handleSubmit];
}
