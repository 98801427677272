import { useSelector } from "react-redux";
import useShowCargoModalI from "./useShowCargoModal";
import { selectUser } from "../ducks/auth/selectors";
import useFeature from "./useFeature";

export const useWebBookingEnabled = () => {
  const me = useSelector(selectUser);
  return useFeature("web-booking") && me && me.features["web-booking"];
};

export const useShowCargoModal = useShowCargoModalI;
export { default as useFeature } from "./useFeature";
export { default as useElementId } from "./useElementId";
