import {
  ERR_CANNOT_DELETE_STOP_WITH_EVENTS,
  ERR_OUTDATED_CARGO_REVISION,
} from "dora-shared";
import { Dialog } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./index.scss";
import {
  faExclamationTriangle,
  faPlus,
  faTimes,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import DoraButton from "../../Toolkit/DoraButton";
import { DoraSelect, UFDoraSelect } from "../../Toolkit/DoraSelect";
import { UFDoraTextInput } from "../../Toolkit/DoraTextInput";
import { UFDoraNumberInput } from "../../Toolkit/DoraNumberInput";
import { UFDoraTextArea } from "../../Toolkit/DoraTextArea";
import CreateEditShipmentFinancesCard from "./finances/CreateEditShipmentFinancesCard";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./create-edit-shipment-validation-schema";
import { cargoTypeNeedsTemperature } from "dora-shared";
import StopList from "./stops/StopList";
import { DateTime } from "luxon";
import { useAppDispatch, useSelector } from "../../../redux-store";
import { getClients } from "../../../ducks/data/clients";
import { UFDoraSelectMultiple } from "../../Toolkit/DoraSelectMultiple";
import { useTranslation } from "react-i18next";
import CreateShipmentData, {
  CreateEditShipmentStop,
} from "./CreateEditShipmentData";
import { nanoid } from "nanoid";
import GenericPromptDialog from "../GenericPromptDialog/GenericPromptDialog";
import DocumentsCard from "./documents/DocumentsCard";
import {
  CargoFormData,
  CargoData,
} from "../../../ducks/app/cargo-dialog/types";
import { selectCustomerContacts } from "../../../ducks/data/economics/customer-contacts/selectors";
import * as actions from "../../../ducks/data/economics/customer-contacts";
import { useNavigate } from "react-router-dom";
import { ContactPersonData } from "../add-edit-cargo/ContactPerson";
import CargoEventsShipment from "../display-cargo/CargoEventsShipment";
import useShipmentFormSelectOptions from "./use-shipment-form-select-options";
import { getEstimatedDistanceForCoords } from "../../../api";
import axios from "../../../axios";
import ClientSelect from "./ClientSelect";
import { selectClients } from "../../../ducks/data/clients/selectors";
import CargoTagSelect from "./CargoTagSelect";
import { useFeature } from "../../../hooks";
import { loadCargo } from "../../../ducks/app/cargo-dialog";
import { loadCargoTemplate } from "../../../ducks/app/cargo-template-dialog";
import WeightEventsTotalWeightAndTimeSpent from "./components/WeightEventsTotalWeightAndTimeSpent";
import ScrollNavigationTabs from "./ScrollNavigationTabs";
import useCargoEvents from "./use-cargo-events";
import { Stop } from "../../../ducks/app/cargo-and-template-shared";
import { loadExtraCO2Types } from "../../../ducks/data/extra-co2-types";
import { selectExtraCO2Types } from "../../../ducks/data/extra-co2-types/selectors";
import Decimal from "decimal.js-light";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import ShipmentDialogClientInfoMailLinks from "./components/ShipmentDialogClientInfoMailLinks";
import useShipmentFormRules from "./use-shipment-form-rules";
import { UFDoraCheckbox } from "../../Toolkit/DoraCheckbox";
import { selectDriverShouldRegisterTimeDefaultSetting } from "../../../ducks/data/org-settings/selectors";

type CreateEditShipmentDialogProps = {
  onClose: (data: { formData?: CargoFormData; cargoId?: string }) => void;
  isSaving: boolean;
  onSubmit: (
    data: CargoFormData & {
      keepAlive?: boolean;
      submitType: "CREATE" | "UPDATE" | "DUPLICATE" | "CREATE_AND_MAKE_COPY";
    }
  ) => Promise<{ id?: string; keepAlive?: boolean }>;
  onDelete: () => void;
  initialData: CargoData;
  resetData: CargoData;
  routeId?: string;
  createNew: boolean;

  isTemplate: boolean;
};

export default function CreateEditShipmentDialog(
  props: CreateEditShipmentDialogProps
) {
  const { t } = useTranslation([
    "components",
    "types",
    "shipmentModal",
    "extraCO2Types",
  ]);
  const {
    routeId,
    onClose,
    onSubmit,
    onDelete,
    isSaving,
    initialData,
    resetData,
    createNew,
    isTemplate,
  } = props;
  const [routeRef, setRouteRef] = useState(null);
  const [
    shouldShowConfirmUpdateCargoDeleteStops,
    setShouldShowConfirmUpdateCargoDeleteStops,
  ] = useState(false);
  const [
    shouldShowOutdatedRevisionDialog,
    setShouldShowOutdatedRevisionDialog,
  ] = useState(false);
  const [
    shouldShowExitWithoutSavingWarning,
    setShouldShowExitWithoutSavingWarning,
  ] = useState(false);

  const userId = initialData.userId || null;

  // canBeDeleted and deliveryId only apply to cargo, not to cargo template
  const [canBeDeleted, setCanBeDeleted] = useState(
    "canBeDeleted" in initialData ? initialData.canBeDeleted : true
  );
  const [deliveryId, setDeliveryId] = useState(
    "deliveryId" in initialData ? initialData.deliveryId : null
  );

  const [id, setId] = useState(initialData.id || null);

  useEffect(() => {
    const shipmentId = initialData.id;
    if (!isTemplate && shipmentId) {
      axios
        .get(`/api/cargos/${shipmentId}/route-ref`)
        .then((res) => setRouteRef(res.data.routeRef))
        .catch((err) => console.error("Unable to load route ref", err));
    }
  }, [initialData, isTemplate]);

  const navigate = useNavigate();
  const [customerContactId, setCustomerContactId] = useState<string | null>(
    initialData.customerContactId
  );
  const [duplicateInProgress, setDuplicateInProgress] = useState(false);
  const [createCopyInProgress, setCreateCopyInProgress] = useState(false);
  const [shouldShowConfirmDeleteDialog, setShouldShowConfirmDeleteDialog] =
    useState(false);
  const [estimatedDistance, setEstimatedDistance] = useState<number | null>(
    null
  );
  const [shouldShowTemperatureInput, setShouldShowTemperatureInput] =
    useState(false);
  const [shouldShowContainerNoInput, setShouldShowContainerNoInput] =
    useState(false);
  const [
    dropoffAccumulatedInputsDisabled,
    setDropoffAccumulatedInputsDisabled,
  ] = useState(false);
  const [shouldShowClientSelect, setShouldShowClientSelect] = useState(true);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (initialData.customerContactId) {
      setCustomerContactId(initialData.customerContactId);
    }
  }, [setCustomerContactId, initialData]);
  useEffect(() => {
    if (initialData.clientId) {
      dispatch(actions.loadCustomerContacts(initialData.clientId));
    }
    dispatch(getClients());
    dispatch(loadExtraCO2Types());
  }, [dispatch, initialData]);
  useCargoEvents({ cargoId: id, isTemplate });

  const clients = useSelector(selectClients);
  const extraCo2Types = useSelector(selectExtraCO2Types);
  const driverShouldRegisterTime = useSelector(
    selectDriverShouldRegisterTimeDefaultSetting
  );

  const tagsEnabled = useFeature("cargo-tags");
  const driverRegisterTimeEnabled = useFeature("driver-cargo-time-register");
  const allowEmptyDropoffsList = useFeature(
    "allow-dispatcher-no-dropoff-shipment"
  );
  const extraCo2FeatureEnabled = useFeature("extra-co2");
  const driverCreateDropdownsCheckboxEnabled = useFeature(
    "driver-create-dropdowns-dispatchers-cargo"
  );

  const liteVersion = useFeature("lite-version");

  const [stopsCoords, setStopsCoords] = useState<
    { lon: number; lat: number }[]
  >(
    [
      ...initialData.pickupList.map((p) => ({
        lon: p.place?.coord?.lon!,
        lat: p.place?.coord?.lat!,
      })),
      ...initialData.dropoffList.map((p) => ({
        lon: p.place?.coord?.lon!,
        lat: p.place?.coord?.lat!,
      })),
    ].filter((p) => p.lon && p.lat)
  );

  useEffect(() => {
    if (stopsCoords.length > 1) {
      getEstimatedDistanceForCoords(stopsCoords).then((res) =>
        setEstimatedDistance(res.data.km)
      );
    }
  }, [stopsCoords]);

  const convertReduxStopDataToFormStopData = (stop: Stop) => {
    return {
      ...stop,
      date: DateTime.fromFormat(stop.date, "yyyy-MM-dd"),
      openingDate: stop.openingDate
        ? DateTime.fromFormat(stop.openingDate, "yyyy-MM-dd")
        : null,
    };
  };

  const {
    cargoTypeOptions,
    qualityOptions,
    trailerQualityOptions,
    teamsOptions,
  } = useShipmentFormSelectOptions();

  const extraCo2Options = useMemo(
    () =>
      extraCo2Types.map((type) => ({
        value: type.id,
        label: `${type.name} (${t(`extraCO2Types:type`, {
          context: type.unit,
        })})`,
      })),
    [extraCo2Types, t]
  );

  const formMethods = useForm<CreateShipmentData>({
    criteriaMode: "all",
    resolver: yupResolver(validationSchema, {
      abortEarly: false,
    }),
    defaultValues: {
      ...initialData,
      driverCanRegisterTime: createNew
        ? driverShouldRegisterTime
        : initialData.driverCanRegisterTime,
      pickupList: initialData.pickupList.map(
        convertReduxStopDataToFormStopData
      ),
      dropoffList: initialData.dropoffList.map(
        convertReduxStopDataToFormStopData
      ),
      qualities: initialData.qualities.map((quality) =>
        qualityOptions.find((o) => o.id === quality)
      ),
      requirements: initialData.requirements.map((requirement) =>
        trailerQualityOptions.find((o) => o.id === requirement)
      ),
      ...("ref" in initialData ? { ref: initialData.ref } : { ref: null }),
      INVOICE_REF_LOCKED: !!initialData.invoiceRef,
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    setValue,
    reset,
    getValues,
    control,
    watch,
  } = formMethods;

  const pickupListWatch = watch("pickupList");
  const dropoffListWatch = watch("dropoffList");

  const pickupNumber = pickupListWatch?.length || 1;
  const dropoffNumber = dropoffListWatch?.length || 1;

  const isSingleStop =
    dropoffListWatch?.length === 0 && pickupListWatch?.length === 1;
  const extraCo2List = watch("extraCo2Elements");
  const { remove, append } = useFieldArray({
    name: "extraCo2Elements",
    control,
  });

  const onCargoTypeSelect = useCallback(() => {
    const cargoType = getValues("type");
    const needsTemp = cargoTypeNeedsTemperature(cargoType);
    const needsContainerNo = cargoType === "CONTAINER";
    setShouldShowContainerNoInput(needsContainerNo);
    setShouldShowTemperatureInput(needsTemp);
    if (!needsTemp) {
      setValue("minTemperature", "");
      setValue("maxTemperature", "");
    } else {
      const minTemp = getValues("minTemperature");
      const maxTemp = getValues("maxTemperature");
      if (!minTemp) {
        setValue("minTemperature", "0");
      }
      if (!maxTemp) {
        setValue("maxTemperature", "0");
      }
    }
    if (!needsContainerNo) {
      setValue("containerNo", "");
    }
  }, [getValues, setValue]);

  useEffect(() => {
    if (id) {
      onCargoTypeSelect();
    }
  }, [id, onCargoTypeSelect, dispatch]);

  const contacts = useSelector(
    selectCustomerContacts(watch("clientId") || null)
  );

  const qualitiesId = useMemo(() => nanoid(), []);

  // contains all the form specific logic in which modifying something leads to
  // another input value / field data being modified
  useShipmentFormRules(
    formMethods,
    setDropoffAccumulatedInputsDisabled,
    setCustomerContactId,
    contacts,
    stopsCoords,
    setStopsCoords,
    setEstimatedDistance,
    qualitiesId
  );

  const onCreateClick = () => submit({ type: "CREATE" });

  const scrollToTop = () => {
    const topElement = document.getElementsByClassName(
      "shipment-dialog__wrapper"
    )[0];
    if (topElement) {
      topElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const onDuplicateClick = async () => {
    setDuplicateInProgress(true);
    try {
      await submit({ type: "UPDATE" });
      const data = await submit({ type: "DUPLICATE" });
      if (data.id) {
        onClose({});
        navigate({ hash: `#cargo=${data.id}` }, { replace: true });
        setId(data.id);
      }
    } catch (err) {
    } finally {
      setDuplicateInProgress(false);
    }
  };

  const resetToInitialData = () => {
    if (!resetData.clientId) {
      setShouldShowClientSelect(false);
      setTimeout(() => {
        setShouldShowClientSelect(true);
      }, 10);
    }
    reset({
      type: resetData.type,
      description: resetData.description,
      clientContactPhone: resetData.clientContactPhone,
      clientContactEmail: resetData.clientContactEmail,
      clientContact: resetData.clientContact,
      customerContactId: resetData.customerContactId,
      note: resetData.note,
      extraCo2Elements: resetData.extraCo2Elements,
      invoiceRef: resetData.invoiceRef,
      pickupList: resetData.pickupList.map(convertReduxStopDataToFormStopData),
      dropoffList: resetData.dropoffList.map(
        convertReduxStopDataToFormStopData
      ),
      assignedTeamId: resetData.assignedTeamId,
      clientId: resetData.clientId,
      ...("ref" in initialData ? { ref: initialData.ref } : { ref: null }),

      qualities: resetData.qualities.map((quality) =>
        qualityOptions.find((o) => o.id === quality)
      ),
      requirements: resetData.requirements.map((requirement) =>
        trailerQualityOptions.find((o) => o.id === requirement)
      ),

      INVOICE_REF_LOCKED: !!resetData.invoiceRef,

      maxTemperature: "",
      minTemperature: "",
      containerNo: "",
    });
  };

  const onCreateAndMakeCopyClick = () => {
    setCreateCopyInProgress(true);
    submit({ type: "CREATE_AND_MAKE_COPY" })
      // .then(() => setValue("ref", createCargoRef()))
      .finally(() => setCreateCopyInProgress(false));
  };

  const onConfirmSaveWithDeletingStopsActivitiesClick = () => {
    setShouldShowConfirmUpdateCargoDeleteStops(false);
    onSaveClick({ deleteStopsWithEvents: true });
  };

  const onSaveClick = (options = { deleteStopsWithEvents: false }) =>
    submit({
      type: "UPDATE",
      deleteStopsWithEvents: options.deleteStopsWithEvents,
    }).then((res: any) => {
      if (res.error === ERR_CANNOT_DELETE_STOP_WITH_EVENTS) {
        setShouldShowConfirmUpdateCargoDeleteStops(true);
        return;
      }
      if (res.error === ERR_OUTDATED_CARGO_REVISION) {
        setShouldShowOutdatedRevisionDialog(true);
        return;
      }
      onClose({
        cargoId: id || undefined,
      });
    });

  const mapStopToAPIFormat = (p: CreateEditShipmentStop): Stop => {
    return {
      ...p,
      temperature: "",
      date: p.date.toFormat("yyyy-MM-dd"),
      openingDate: p.openingDate?.toFormat("yyyy-MM-dd") || null,
      place: p.place,
      flipKey: "",
      modified: false,
    };
  };

  const submit = useCallback(
    async (options: {
      type: "CREATE" | "UPDATE" | "DUPLICATE" | "CREATE_AND_MAKE_COPY";
      deleteStopsWithEvents?: boolean;
      keepAlive?: boolean;
    }): Promise<{ id?: string; keepAlive?: boolean }> => {
      scrollToTop();
      return new Promise((resolve, reject) => {
        handleSubmit(
          async () => {
            const data: Omit<CargoData, "ref"> & {
              keepAlive?: boolean;
              deleteStopsWithEvents?: boolean;
            } = {
              ...getValues(),
              route_id: routeId,
              customerContactId: customerContactId || null,

              // adjustments for old format data
              pickupList: getValues("pickupList").map(mapStopToAPIFormat),
              dropoffList: getValues("dropoffList").map(mapStopToAPIFormat),

              keepAlive: options.keepAlive,
              deleteStopsWithEvents: options.deleteStopsWithEvents,

              // fix this nonsense
              qualities: getValues("qualities").map((q) => q.id),
              requirements: getValues("requirements").map((q) => q.id),

              // not implemented yet
              smartMatch: false,

              ...(options.type === "UPDATE" && {
                id: initialData.id,
                keepAlive: true,
              }),
              ...(options.type === "DUPLICATE" && {
                keepAlive: true,
              }),
              ...(options.type === "CREATE_AND_MAKE_COPY" && {
                keepAlive: true,
              }),
            };

            onSubmit({
              ...data,
              submitType: options.type,
            } as any) // TODO: fix this if possible
              .then(resolve)
              .catch((err) => reject(err));
          },
          (_) => {
            reject("VALIDATION_ERROR");
          }
        )();
      });
    },
    [customerContactId, getValues, handleSubmit, onSubmit, routeId, initialData]
  );

  const [stopFutureNavigation, setStopFutureNavigation] = useState(false);

  const onSaveKeysPressed = useCallback(
    (e: KeyboardEvent) => {
      const isCtrlOrCmd = e.ctrlKey || e.metaKey;
      if (isCtrlOrCmd && e.key === "s") {
        e.preventDefault(); // Prevent the default browser save behavior
        submit({ type: createNew ? "CREATE" : "UPDATE", keepAlive: true }).then(
          (data: any) => {
            if (data.error === ERR_CANNOT_DELETE_STOP_WITH_EVENTS) {
              setShouldShowConfirmUpdateCargoDeleteStops(true);
              return;
            }
            if (data.error === ERR_OUTDATED_CARGO_REVISION) {
              setShouldShowOutdatedRevisionDialog(true);
              return;
            }

            const values = getValues();
            reset({ ...values, INVOICE_REF_LOCKED: !!values.invoiceRef });
            const entityId = data.id || id;
            if (entityId) {
              // TODO: should be adapted for templates
              const action = isTemplate
                ? loadCargoTemplate(entityId)
                : loadCargo(entityId);
              dispatch(action as any).then(
                (loadDataResponse: { payload: { data: CargoData } }) => {
                  const cargoData: any = loadDataResponse.payload.data;
                  if (!isTemplate) {
                    setDeliveryId(cargoData.deliveryId);
                  }
                  setId(cargoData.id);
                  for (let i = 0; i < cargoData.pickupList.length; i++) {
                    setValue(
                      `pickupList[${i}].id` as any,
                      cargoData.pickupList[i].id
                    );
                  }
                  for (let i = 0; i < cargoData.dropoffList.length; i++) {
                    setValue(
                      `dropoffList[${i}].id` as any,
                      cargoData.dropoffList[i].id
                    );
                  }
                  if (createNew) {
                    setCanBeDeleted(true);
                  }
                  if (!stopFutureNavigation) {
                    navigate(
                      {
                        hash: `${isTemplate ? "cargoTemplate" : "cargo"}=${entityId}`,
                      },
                      { replace: true }
                    );
                  }
                }
              );
            }
          }
        );
      }
    },
    [
      createNew,
      submit,
      navigate,
      dispatch,
      setDeliveryId,
      setId,
      id,
      setValue,
      isTemplate,
      getValues,
      reset,
      stopFutureNavigation,
    ]
  );

  useEffect(() => {
    document.addEventListener("keydown", onSaveKeysPressed);
    return () => {
      document.removeEventListener("keydown", onSaveKeysPressed);
    };
  }, [onSaveKeysPressed]);

  // There are problems with use-forms yup resolver. See these issues:
  // https://github.com/react-hook-form/resolvers/issues/331
  // https://github.com/orgs/react-hook-form/discussions/6472
  const e = (errors as any)[""]?.types;
  const topLevelErrors =
    e?.lmMatch ||
    e?.colliMatch ||
    e?.unitsMatch ||
    e?.pickupTimesBeforeDropoffTimes;

  const pickupsNumber = getValues("pickupList").length;
  const dropoffsNumber = getValues("dropoffList").length;

  const isDriversCanAddStopsCheckboxDisabled = useMemo(() => {
    return pickupsNumber > 1;
  }, [pickupsNumber]);

  const clientInputId = useMemo(() => nanoid(), []);
  const emailId = useMemo(() => nanoid(), []);
  const invoiceRefId = useMemo(() => nanoid(), []);
  const shipmentRefId = useMemo(() => nanoid(), []);
  const contactId = useMemo(() => nanoid(), []);
  const phoneId = useMemo(() => nanoid(), []);
  const driverCanAddDropoffsId = useMemo(() => nanoid(), []);
  const driverShouldRegisterTimeId = useMemo(() => nanoid(), []);
  const teamId = useMemo(() => nanoid(), []);
  const trailerDemandsId = useMemo(() => nanoid(), []);
  const noteId = useMemo(() => nanoid(), []);

  const clientIdWatch = watch("clientId");
  const customerContactWatch = watch("clientContact");
  const invoiceRefWatch = watch("invoiceRef");
  const driverCanAddStopsChecked = watch("driverCanAddDropoffs");

  const modalTitle = isTemplate
    ? createNew
      ? t("shipmentModal:titleNewTemplate")
      : t("shipmentModal:titleEditTemplate")
    : createNew
      ? t("shipmentModal:titleNew")
      : t("shipmentModal:titleEdit");

  const handleOnClose = () => {
    if (initialData.id && !isTemplate && Object.keys(dirtyFields).length > 0) {
      setShouldShowExitWithoutSavingWarning(true);
      return;
    }
    close();
  };

  const close = () => {
    setStopFutureNavigation(true);
    onClose({
      formData: {
        ...getValues(),

        // adjustments for old format data
        pickupList: getValues("pickupList").map(mapStopToAPIFormat),
        dropoffList: getValues("dropoffList").map(mapStopToAPIFormat),

        // will be removed from top level
        minTemperature: "",
        maxTemperature: "",

        // not implemented yet
        // route_id: routeId, <-- TODO: this does not exist on CargoFormData
        smartMatch: false,

        // fix this nonsense
        qualities: getValues("qualities").map((q) => q.id),
        requirements: getValues("requirements").map((q) => q.id),
      } as any, // TODO: fix this if possible
      cargoId: initialData.id,
    });
  };

  const adrWatch = watch("adr");
  useEffect(() => {
    if (!adrWatch) {
      setValue("adrText", "");
    }
  }, [adrWatch, setValue]);

  // This should be used somehow with useForms instead (controlled component)
  const onClientContactChange = (data: Partial<ContactPersonData>) => {
    if (data.contactPersonId !== undefined) {
      setCustomerContactId(data.contactPersonId);
    }
    setValue("clientContact", data.name || "");
    if (data.phone !== undefined) {
      setValue("clientContactPhone", data.phone);
    }
    if (data.email !== undefined) {
      setValue("clientContactEmail", data.email);
    }
  };

  const onContactInputValueChange = (v: string) => {
    const contact = contacts?.find((c) => c.name === v);
    if (contact) {
      onClientContactChange({
        name: contact.name,
        contactPersonId: contact.id,
        phone: contact.phone || "",
        email: contact.email || "",
      });
    } else {
      onClientContactChange({
        name: v,
        contactPersonId: null,
      });
    }
  };

  const onAddExtraCo2Elements = () => {
    append({
      value: new Decimal(0),
      extraCo2TypeId: extraCo2Types[0].id,
    });
  };

  const onRemoveExtraCo2Elements = (index: number) => {
    remove(index);
  };

  return (
    <Dialog fullScreen open onClose={handleOnClose}>
      {shouldShowExitWithoutSavingWarning && (
        <GenericPromptDialog
          title={t("shipmentModal:confirmExitWithoutSaving.title")}
          text={t("shipmentModal:confirmExitWithoutSaving.message")}
          onCancelClick={() => setShouldShowExitWithoutSavingWarning(false)}
          onContinueClick={close}
          cancelText={t("shipmentModal:confirmExitWithoutSaving.cancel")}
          continueText={t("shipmentModal:confirmExitWithoutSaving.continue")}
        />
      )}
      {shouldShowConfirmDeleteDialog && (
        <GenericPromptDialog
          title={t("shipmentModal:confirmShipmentDelete.title")}
          text={t("shipmentModal:confirmShipmentDelete.message")}
          onCancelClick={() => setShouldShowConfirmDeleteDialog(false)}
          continueButtonColor={"error"}
          continueText={t("components:buttonLabels.delete")}
          onContinueClick={onDelete}
        />
      )}
      {shouldShowOutdatedRevisionDialog && (
        <GenericPromptDialog
          title={t("shipmentModal:outdatedRevision.title")}
          text={t("shipmentModal:outdatedRevision.message")}
          cancelText={t("shipmentModal:outdatedRevision.close")}
          continueText={t("shipmentModal:outdatedRevision.back")}
          onCloseClick={() => setShouldShowOutdatedRevisionDialog(false)}
          onCancelClick={() => {
            setShouldShowOutdatedRevisionDialog(false);
            onClose({});
          }}
          onContinueClick={() => {
            setShouldShowOutdatedRevisionDialog(false);
          }}
        />
      )}
      {shouldShowConfirmUpdateCargoDeleteStops && (
        <GenericPromptDialog
          title={t("shipmentModal:confirmSaveShipmentWithDeletingStops.title")}
          text={t("shipmentModal:confirmSaveShipmentWithDeletingStops.message")}
          onCancelClick={() =>
            setShouldShowConfirmUpdateCargoDeleteStops(false)
          }
          onContinueClick={onConfirmSaveWithDeletingStopsActivitiesClick}
          continueButtonColor="error"
        />
      )}
      <FormProvider {...formMethods}>
        <div className="shipment-dialog__wrapper">
          <div className="shipment-dialog__sticky-header">
            <div className="shipment-dialog__top-bar">
              <div className="shipment-dialog-top-bar__left">
                <div className="shipment-dialog__title">{modalTitle}</div>
              </div>
              <div className="shipment-dialog-top-bar__right">
                {createNew ? (
                  <>
                    <DoraButton
                      variant={"ghost"}
                      onClick={resetToInitialData}
                      disabled={isSaving}
                    >
                      {t("components:buttonLabels.clear")}
                    </DoraButton>
                    {!isTemplate && (
                      <DoraButton
                        variant="primary"
                        disabled={isSaving}
                        onClick={onCreateAndMakeCopyClick}
                      >
                        {createCopyInProgress
                          ? t("shipmentModal:copyInProgress")
                          : t("shipmentModal:createAndMakeCopy")}
                      </DoraButton>
                    )}
                    <DoraButton
                      variant="primary"
                      className="shipment-dialog-top-bar__back-button"
                      disabled={isSaving}
                      onClick={handleOnClose}
                    >
                      {t("components:buttonLabels.cancel")}
                    </DoraButton>
                    <DoraButton
                      disabled={isSaving}
                      variant="primary-filled"
                      onClick={onCreateClick}
                    >
                      {t("components:buttonLabels.create")}
                    </DoraButton>
                  </>
                ) : (
                  <>
                    <DoraButton
                      title={t("shipmentModal:cannotDeleteInvoiced")}
                      disabled={isSaving || !canBeDeleted}
                      variant={"error"}
                      onClick={() => setShouldShowConfirmDeleteDialog(true)}
                    >
                      {t("shipmentModal:delete")}
                    </DoraButton>
                    {!isTemplate && !liteVersion && (
                      <DoraButton
                        variant={"primary"}
                        onClick={onDuplicateClick}
                        disabled={isSaving}
                      >
                        {duplicateInProgress
                          ? t("shipmentModal:duplicateProgress")
                          : t("shipmentModal:duplicate")}
                      </DoraButton>
                    )}
                    <DoraButton
                      disabled={isSaving}
                      variant={"ghost"}
                      onClick={resetToInitialData}
                    >
                      {t("shipmentModal:undoChanges")}
                    </DoraButton>
                    <DoraButton
                      variant="primary"
                      className="shipment-dialog-top-bar__back-button"
                      disabled={isSaving}
                      onClick={handleOnClose}
                    >
                      {t("components:buttonLabels.cancel")}
                    </DoraButton>
                    <DoraButton
                      disabled={isSaving}
                      variant={"primary-filled"}
                      onClick={() => onSaveClick()}
                    >
                      {t("components:buttonLabels.save")}
                    </DoraButton>
                  </>
                )}
              </div>
            </div>
            <div className="shipment-dialog__second-row">
              {liteVersion || isTemplate ? (
                <div></div>
              ) : (
                <ScrollNavigationTabs />
              )}
              <div className="shipment-dialog__shipment-stats">
                {!liteVersion && (
                  <div className="shipment-dialog__shipment-stat">
                    <div>{t("shipmentModal:inputs.routeRefLabel")}</div>
                    <div>{routeRef || "-"}</div>
                  </div>
                )}
                {!isTemplate && <WeightEventsTotalWeightAndTimeSpent />}
                <div className="shipment-dialog__shipment-stat">
                  <div>{t("shipmentModal:estDistance")}</div>
                  <div>
                    {estimatedDistance
                      ? `${estimatedDistance.toString().replace(".", ",")} km`
                      : "-"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {topLevelErrors && (
            <div className="shipment-dialog__errors-section">
              <FontAwesomeIcon
                className="shipment-dialog-errors-section__icon"
                icon={faExclamationTriangle}
              />
              <div>
                <div className="shipment-dialog-errors-section__title">
                  {t("shipmentModal:errors.headerGenericFail")}
                </div>
                <ul>
                  {e.lmMatch && <li>{t("shipmentModal:errors.lmMatch")}</li>}
                  {e.colliMatch && (
                    <li>{t("shipmentModal:errors.colliMatch")}</li>
                  )}
                  {e.unitsMatch && (
                    <li>{t("shipmentModal:errors.unitsMatch")}</li>
                  )}
                  {e.pickupTimesBeforeDropoffTimes && (
                    <li>
                      {t("shipmentModal:errors.timesPickupsBeforeDropoffs")}
                    </li>
                  )}
                </ul>
              </div>
            </div>
          )}
          <div className="shipment-dialog__content">
            <div className="shipment-dialog__content-left">
              <div
                id="shipment-dialog-section__shipment"
                className="shipment-dialog__content-card"
              >
                <div className="shipment-cargo-list__first-row">
                  <div className="shipment-cargo-type-dropdowns">
                    <div>
                      <UFDoraSelect
                        autofocus
                        name={"type"}
                        control={control}
                        placeholder={"Type"}
                        options={cargoTypeOptions}
                        onChange={onCargoTypeSelect}
                        error={!!errors.type}
                      />
                      {errors.type && (
                        <div className="shipment-cargo-form__error">
                          {t(
                            `shipmentModal:errors.${errors.type.message}` as any
                          )}
                        </div>
                      )}
                    </div>
                    {shouldShowContainerNoInput && (
                      <UFDoraTextInput
                        register={register("containerNo")}
                        placeholder={"No."}
                      />
                    )}
                    {shouldShowTemperatureInput && (
                      <>
                        <UFDoraNumberInput
                          register={register("minTemperature")}
                          className={"shipment-cargo-form__temperature-input"}
                          suffix={"°C"}
                          placeholder={"Min."}
                          error={!!errors.minTemperature}
                        />
                        <UFDoraNumberInput
                          register={register("maxTemperature")}
                          className={"shipment-cargo-form__temperature-input"}
                          suffix={"°C"}
                          placeholder={"Max."}
                          error={!!errors.maxTemperature}
                        />
                      </>
                    )}
                  </div>
                  <div className="flex gap-6 items-center">
                    <div>
                      {!liteVersion && driverCreateDropdownsCheckboxEnabled && (
                        <div className="flex gap-2 items-center">
                          <UFDoraCheckbox
                            id={driverCanAddDropoffsId}
                            register={register("driverCanAddDropoffs")}
                            disabled={isDriversCanAddStopsCheckboxDisabled}
                          />
                          <label
                            className="shipment-dialog-input-list__label"
                            htmlFor={driverCanAddDropoffsId}
                            style={{ fontSize: 14 }}
                          >
                            {t(
                              "shipmentModal:inputs.driverCanAddDropoffsLabel"
                            )}
                          </label>
                        </div>
                      )}
                      {!liteVersion && driverRegisterTimeEnabled && (
                        <div className="flex gap-2 items-center">
                          <UFDoraCheckbox
                            id={driverShouldRegisterTimeId}
                            register={register("driverCanRegisterTime")}
                            disabled={isDriversCanAddStopsCheckboxDisabled}
                          />
                          <label
                            className="shipment-dialog-input-list__label"
                            htmlFor={driverShouldRegisterTimeId}
                            style={{ fontSize: 14 }}
                          >
                            {t("shipmentModal:inputs.driverShouldRegisterTime")}
                          </label>
                        </div>
                      )}
                    </div>
                    {!liteVersion && tagsEnabled && (
                      <div style={{ minWidth: 250 }}>
                        <CargoTagSelect
                          className="shipment-cargo-tag-dropdown"
                          name={"cargoTagId"}
                          control={control}
                          valueForInputColor={watch("cargoTagId")}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <UFDoraTextInput
                  register={register("description")}
                  className="shipment-cargo-list__name-input"
                  placeholder={t("shipmentModal:inputs.shipmentDescription")}
                />
                <div className="shipment-cargo-forms-section">
                  <div className="shipment-cargo-forms-section__column">
                    <StopList
                      isPickup
                      errors={errors}
                      addButtonDisabled={
                        dropoffsNumber > 1 || driverCanAddStopsChecked
                      }
                      isSingleStop={isSingleStop}
                      relativeFromPath="pickupList"
                      createNew={createNew}
                    />
                  </div>
                  <div className="shipment-cargo-forms-section__column">
                    <StopList
                      errors={errors}
                      disableAccumulatedInputs={
                        dropoffAccumulatedInputsDisabled
                      }
                      stopsLengthChanged={() => {
                        const values = getValues("dropoffList");
                        if (values.length === 1) {
                          setDropoffAccumulatedInputsDisabled(true);
                        } else {
                          setDropoffAccumulatedInputsDisabled(false);
                        }
                      }}
                      addButtonDisabled={
                        pickupsNumber > 1 &&
                        (!allowEmptyDropoffsList ||
                          (allowEmptyDropoffsList && dropoffsNumber === 1))
                      }
                      relativeFromPath="dropoffList"
                      createNew={createNew}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="shipment-dialog__content-right">
              <div className="shipment-dialog__content-card">
                <h3 className="shipment-dialog__content-card-title">
                  {t("shipmentModal:clientInfoTitle")}
                </h3>
                <div
                  style={{ paddingBottom: 42 }}
                  className="shipment-dialog__content-card-right-input-list"
                >
                  <div className="shipment-dialog-input-list__row">
                    <label
                      className="shipment-dialog-input-list__label"
                      htmlFor={clientInputId}
                    >
                      {t("shipmentModal:inputs.clientLabel")}
                    </label>
                    {shouldShowClientSelect && (
                      <ClientSelect
                        id={clientInputId}
                        placeholder={t("shipmentModal:inputs.clientLabel")}
                        refreshButtonLabel={t(
                          "shipmentModal:inputs.clientRefreshButtonLabel"
                        )}
                        name={"clientId"}
                        control={control}
                        clients={clients}
                      />
                    )}
                  </div>
                  <div className="shipment-dialog-input-list__row">
                    <label
                      className="shipment-dialog-input-list__label"
                      htmlFor={invoiceRefId}
                    >
                      {t("shipmentModal:inputs.invoiceRefLabel")}
                    </label>
                    <UFDoraTextInput
                      id={invoiceRefId}
                      register={register("invoiceRef")}
                      placeholder={t("shipmentModal:inputs.invoiceRefLabel")}
                    />
                  </div>
                  {id && (
                    <div className="shipment-dialog-input-list__row">
                      <label
                        className="shipment-dialog-input-list__label"
                        htmlFor={shipmentRefId}
                      >
                        {t("shipmentModal:inputs.shipmentRefLabel")}
                      </label>
                      <UFDoraTextInput
                        id={shipmentRefId}
                        register={register("ref")}
                        placeholder={t("shipmentModal:inputs.shipmentRefLabel")}
                        disabled
                      />
                    </div>
                  )}
                  <div className="shipment-dialog-input-list__row">
                    <label
                      className="shipment-dialog-input-list__label"
                      htmlFor={contactId}
                    >
                      {t("shipmentModal:inputs.contactLabel")}
                    </label>
                    <DoraSelect
                      id={contactId}
                      options={
                        contacts
                          ? contacts.map((c) => ({
                              label: c.name,
                              value: c.id,
                            }))
                          : []
                      }
                      value={customerContactId || watch("clientContact")}
                      fullWidth
                      onInputChange={(_, v) => onContactInputValueChange(v)}
                      freeSolo
                      placeholder={t("shipmentModal:inputs.contactPlaceholder")}
                    />
                  </div>
                  <div className="shipment-dialog-input-list__row">
                    <div className="flex justify-between items-center">
                      <label
                        className="shipment-dialog-input-list__label"
                        htmlFor={emailId}
                      >
                        {t("shipmentModal:inputs.emailLabel")}
                      </label>
                    </div>
                    <UFDoraTextInput
                      id={emailId}
                      register={register("clientContactEmail")}
                      placeholder={t("shipmentModal:inputs.emailPlaceholder")}
                    />
                  </div>
                  <div className="shipment-dialog-input-list__row">
                    <label
                      className="shipment-dialog-input-list__label"
                      htmlFor={phoneId}
                    >
                      {t("shipmentModal:inputs.phoneLabel")}
                    </label>
                    <UFDoraTextInput
                      id={phoneId}
                      register={register("clientContactPhone")}
                      placeholder={t("shipmentModal:inputs.phonePlaceholder")}
                    />
                  </div>
                  {!isTemplate && id && (
                    <div className="shipment-dialog-input-list__row">
                      <ShipmentDialogClientInfoMailLinks />
                    </div>
                  )}
                </div>
              </div>
              <div className="shipment-dialog__content-card">
                <h3 className="shipment-dialog__content-card-title">
                  {t("shipmentModal:aboutShipmentTitle")}
                </h3>
                <div
                  style={{ paddingBottom: 16 }}
                  className="shipment-dialog__content-card-right-input-list"
                >
                  <div className="shipment-dialog-input-list__row">
                    <label className="shipment-dialog-input-list__label">
                      {t("shipmentModal:inputs.adrLabel")}
                    </label>
                    <div className="flex gap-2 items-center">
                      <UFDoraCheckbox register={register("adr")} />
                      <div className="flex-grow">
                        <UFDoraTextInput
                          placeholder={t("shipmentModal:inputs.adrPlaceholder")}
                          disabled={!adrWatch}
                          register={register("adrText")}
                        />
                      </div>
                    </div>
                  </div>
                  {!liteVersion && (
                    <div className="shipment-dialog-input-list__row">
                      <label
                        className="shipment-dialog-input-list__label"
                        htmlFor={teamId}
                      >
                        {t("shipmentModal:inputs.assignedTeamLabel")}
                        <span aria-hidden>*</span>
                      </label>
                      <UFDoraSelect
                        id={teamId}
                        name={"assignedTeamId"}
                        control={control}
                        fullWidth
                        placeholder={t(
                          "shipmentModal:inputs.assignedTeamLabel"
                        )}
                        options={teamsOptions}
                        icon={faUsers}
                      />
                    </div>
                  )}
                  <div className="shipment-dialog-input-list__row">
                    <label
                      className="shipment-dialog-input-list__label"
                      htmlFor={qualitiesId}
                    >
                      {t("shipmentModal:inputs.qualitiesLabel")}
                    </label>
                    <UFDoraSelectMultiple
                      id={qualitiesId}
                      placeholder={t(
                        "shipmentModal:inputs.qualitiesPlaceholder"
                      )}
                      control={control}
                      name={"qualities"}
                      options={qualityOptions}
                    />
                  </div>
                  <div className="shipment-dialog-input-list__row">
                    <label
                      className="shipment-dialog-input-list__label"
                      htmlFor={trailerDemandsId}
                    >
                      {t("shipmentModal:inputs.demandsLabel")}
                    </label>
                    <UFDoraSelectMultiple
                      id={trailerDemandsId}
                      placeholder={t("shipmentModal:inputs.demandsPlaceholder")}
                      control={control}
                      name={"requirements"}
                      options={trailerQualityOptions}
                    />
                  </div>
                  <div className="shipment-dialog-input-list__row">
                    <label
                      className="shipment-dialog-input-list__label"
                      htmlFor={noteId}
                    >
                      {t("shipmentModal:inputs.noteLabel")}
                    </label>
                    <UFDoraTextArea
                      id={noteId}
                      register={register("note")}
                      placeholder={t("shipmentModal:inputs.notePlaceholder")}
                    />
                  </div>
                  {!liteVersion && extraCo2FeatureEnabled && (
                    <>
                      <div className="">
                        <label className="shipment-dialog-input-list__label">
                          {t("shipmentModal:inputs.extraCo2Label")}
                        </label>
                        <div className="">
                          {extraCo2List?.length > 0 &&
                            extraCo2List.map((_, index) => {
                              return (
                                <div
                                  key={index}
                                  className="grid grid-cols-12 my-2"
                                >
                                  <div className="col-span-3">
                                    <UFDoraNumberInput
                                      register={register(
                                        `extraCo2Elements.${index}.value`,
                                        {
                                          setValueAs: (v) => new Decimal(v),
                                        }
                                      )}
                                    />
                                  </div>
                                  <div className="col-span-1 flex items-center justify-center">
                                    <FontAwesomeIcon
                                      className="shipment-dialog-input-list__times"
                                      icon={faTimes}
                                    />
                                  </div>
                                  <div className="col-span-6">
                                    <UFDoraSelect
                                      name={`extraCo2Elements.${index}.extraCo2TypeId`}
                                      control={control}
                                      fullWidth
                                      options={extraCo2Options}
                                      className="w-full"
                                    />
                                  </div>
                                  <div className="col-span-2 flex">
                                    <DoraButton
                                      onClick={() =>
                                        onRemoveExtraCo2Elements(index)
                                      }
                                      variant="ghost"
                                      className="w-full"
                                    >
                                      <FontAwesomeIcon icon={faTrashAlt} />
                                    </DoraButton>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      <DoraButton
                        variant={"ghost"}
                        onClick={onAddExtraCo2Elements}
                        disabled={extraCo2Types.length === 0}
                        className="text-left"
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          style={{ marginRight: 8 }}
                        />
                        {t("shipmentModal:inputs.addCo2Elements")}
                      </DoraButton>
                    </>
                  )}
                </div>
              </div>
              {!isTemplate && (
                <div className="shipment-dialog__content-card">
                  <div className="shipment-dialog__documents-card-wrapper">
                    <DocumentsCard
                      cargoId={id}
                      createNew={createNew}
                      pickupNumber={pickupNumber}
                      dropoffNumber={dropoffNumber}
                    />
                  </div>
                </div>
              )}
            </div>
            {!liteVersion && !isTemplate && (
              <>
                <div className="shipment-dialog__content-left">
                  <div
                    id="shipment-dialog-section__finances"
                    className="shipment-dialog__content-card"
                  >
                    <h2 className="shipment-dialog__finances--title">
                      {t("shipmentModal:tabs.finances")}
                    </h2>
                    {!createNew && deliveryId && id ? (
                      <CreateEditShipmentFinancesCard
                        cargoId={id}
                        deliveryId={deliveryId}
                        clientId={clientIdWatch}
                        clientContact={customerContactId}
                        clientContactName={customerContactWatch}
                        invoiceRef={invoiceRefWatch}
                        onInvoiceSent={() => setCanBeDeleted(false)}
                      />
                    ) : (
                      <div className="shipment-dialog__content-card-placeholder-text">
                        {t("shipmentModal:cards.finances.availableLater")}
                      </div>
                    )}
                  </div>
                </div>
                <div className="shipment-dialog__content-right">
                  <div
                    id="shipment-dialog-section__log"
                    className="shipment-dialog__content-card"
                  >
                    <div className="shipment-dialog__log-section">
                      <div className="shipment-dialog-log-section__title">
                        Log
                      </div>
                      {id ? (
                        <div className="shipment-dialog__logs-card-wrapper">
                          <CargoEventsShipment
                            hideTitle
                            createdByDispatcherId={userId}
                            cargoId={id}
                          />
                        </div>
                      ) : (
                        <div className="shipment-dialog__content-card-placeholder-text">
                          {t("shipmentModal:cards.logs.availableLater")}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </FormProvider>
    </Dialog>
  );
}
