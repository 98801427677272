import { useStableNavigate } from "../Components/StableNavigateContext";

const useShowCargoModal = () => {
  const navigate = useStableNavigate();
  return (id: string) => {
    navigate({ hash: `cargo=${id}` });
  };
};

export default useShowCargoModal;
