import { useTranslation } from "react-i18next";
import { DoraLink } from "../../Toolkit/DoraLinkButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-regular-svg-icons";

const LoadingListDownload = ({ routeId }: { routeId: string }) => {
  const { t } = useTranslation(["dispatching", "translation"]);
  const supportedLoadingListPdfLanguages = ["da", "en"] as const;
  return (
    <>
      {supportedLoadingListPdfLanguages.map((lang) => (
        <div key={lang}>
          <DoraLink
            className="flex items-center gap-2 whitespace-nowrap"
            url={`/api/loading-list/pdf?routeId=${routeId}&lang=${lang}`}
            newTab
          >
            <FontAwesomeIcon icon={faFile} />
            {t("dispatching:openRouteView.actions.loadingUnloadingList")} -{" "}
            {t("translation:language", { context: lang })}
          </DoraLink>
        </div>
      ))}
    </>
  );
};

export default LoadingListDownload;
