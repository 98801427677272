import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import React, { FC, useCallback } from "react";
import { Matching } from "../../../models/matching";
import { SendEmailDirectly } from "./send-email-directly";
import { acceptPrice, requestPrice } from "../../../ducks/smart-match";
import Decimal from "decimal.js-light";
import { useAppDispatch } from "../../../redux-store";
import { useTranslation } from "react-i18next";
import { CargoView } from "../../../ducks/data/cargo-views/types";
import { notify } from "../../../ducks/notifications";

interface Props {
  matching: Matching;
  cargoInfo: CargoView;
}

interface ActionElementProps {
  matching: Matching;
  onAcceptPrice: (price: Decimal) => void;
  onRequestPrice: () => void;
}

let ActionElement = (props: ActionElementProps) => {
  const { t } = useTranslation(["dispatching"]);
  const { matching, onRequestPrice } = props;
  const onAcceptPrice = () => {
    props.onAcceptPrice(new Decimal(matching.price));
  };
  if (matching.requested) {
    if (matching.accepted) {
      return (
        <Grid item key="1">
          <Typography variant="body2">
            {t("dispatching:smartMatch.accepted")}
          </Typography>
        </Grid>
      );
    } else {
      if (props.matching.price) {
        return (
          <>
            <Grid item key="1">
              <Typography variant="body2">
                {t("dispatching:smartMatch.priceGiven")}: {matching.price} kr.
              </Typography>
            </Grid>
            <Grid item key="2">
              <Button
                color="primary"
                variant="contained"
                onClick={onAcceptPrice}
              >
                {t("dispatching:smartMatch.actions.buttonAcceptPrice")}
              </Button>
            </Grid>
          </>
        );
      } else {
        return (
          <Grid item key="1">
            <Typography variant="body2">
              {t("dispatching:smartMatch.awaitingPrice")}
            </Typography>
          </Grid>
        );
      }
    }
  } else {
    return (
      <Grid item key="1">
        <Button color="primary" variant="contained" onClick={onRequestPrice}>
          {t("dispatching:smartMatch.actions.buttonRequestPrice")}
        </Button>
      </Grid>
    );
  }
};

export const RequestPrice: FC<Props> = React.memo((props) => {
  const dispatch = useAppDispatch();
  const matchId = props.matching.id;

  const onRequestPrice = useCallback(() => {
    dispatch(requestPrice(matchId));
  }, [dispatch, matchId]);

  const onAcceptPrice = useCallback(
    (price: Decimal) => {
      try {
        dispatch(acceptPrice({ id: matchId, price }));
      } catch (err: any) {
        if (
          typeof err === "object" &&
          err.isAxiosError &&
          err.response.status === 409
        ) {
          dispatch(
            notify({
              message: "Smart match er ikke længere tilgængelig",
              type: "error",
            })
          );
        } else {
          throw err;
        }
      }
    },
    [matchId, dispatch]
  );

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      sx={{ padding: "16px" }}
    >
      <Grid item>
        <Grid container spacing={1} direction="column">
          <Grid item>
            <Typography variant="body2">
              {props.matching.first} fra {props.matching.company_name}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">+45 93 93 93 93</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{props.matching.email}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="flex-start" spacing={1}>
          <ActionElement
            matching={props.matching}
            onAcceptPrice={onAcceptPrice}
            onRequestPrice={onRequestPrice}
          />

          <Grid item>
            <Button color="primary" startIcon={<EmailOutlinedIcon />}>
              <SendEmailDirectly
                matching={props.matching}
                cargoInfo={props.cargoInfo}
              />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});
