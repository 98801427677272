import Paper from "@mui/material/Paper";
import { useCallback, useState } from "react";
import { CargoView } from "../../../ducks/data/cargo-views/types";
import { Matching } from "../../../models/matching";
import { CargoInfo } from "../../../pages/DispatchPage/CargoList/CargoCard";
import { RequestPrice } from "./request-price";

interface Props {
  cargoInfo: CargoView;
  matching?: Matching;
}
export const SmartMatchingCargo = (props: Props) => {
  const [active, setActive] = useState(false);

  const onSetActive = useCallback(() => {
    setActive(!active);
  }, [active]);

  return (
    <Paper elevation={active ? 3 : 0}>
      <div onClick={onSetActive}>
        <CargoInfo cargo={props.cargoInfo} />
      </div>
      {active && props.matching && (
        <RequestPrice matching={props.matching} cargoInfo={props.cargoInfo} />
      )}
    </Paper>
  );
};
