import { useMemo } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import RoomIcon from "@mui/icons-material/Room";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../redux-store";
import useShowCargoModal from "../../../hooks/useShowCargoModal";
import { setHoveredCargoId } from "../../../ducks/cargo";
import {
  formatCustomDateString,
  formatDateAndTime,
} from "../../../helpers/date-helper";
import {
  selectCompletedStop,
  selectDepartedStop,
  selectRouteStopStatus,
} from "../../../ducks/data/driver-sessions/selectors";
import { Tooltip, IconButton } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { selectDriver } from "../../../ducks/data/drivers/selectors";
import { useTranslation } from "react-i18next";
import { useL10n } from "../../../l10n";
import { RouteCargoStop } from "../../../ducks/app/route-info/stops/selectors";
import { RootState } from "../../../redux-store";
import RouteStopContainer from "./RouteStopContainer";
import { CompletedStop } from "../../../ducks/data/driver-sessions";
import { selectHideLoadmeters } from "../../../ducks/data/org-settings/selectors";
import { selectClientForCargo } from "../../../ducks/data/clients/selectors";
import { faCircleUser } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFeature } from "../../../hooks";
import {
  selectCompletedStopFromActivities,
  selectDepartedStopFromActivities,
} from "../../../ducks/data/driver-activities/selectors";
import { DateTime } from "luxon";

const CompletionIndicator = ({
  routeStop,
  completedStop,
}: {
  routeStop: RouteCargoStop;
  completedStop?: {
    stopId: string;
    date: DateTime;
    driverId: string;
  };
}) => {
  const { t } = useTranslation(["translation", "dispatching"]);
  const driver = useSelector(selectDriver(completedStop?.driverId));
  const isPickup = routeStop.stopType === "PICKUP";
  if (!completedStop || !driver)
    return isPickup ? (
      <ArrowCircleUpIcon color="disabled" />
    ) : (
      <RoomIcon color="secondary" />
    );

  const text = isPickup
    ? `${t("dispatching:cargoCard.collected")}`
    : `${t("dispatching:cargoCard.delivered")}`;
  return (
    <Tooltip
      title={
        <>
          {`${t("dispatching:routeCard.driver")}`}:{" "}
          {`${driver.firstName} ${driver.lastName}`}
          <br />
          {text}: {formatDateAndTime(completedStop.date)}
        </>
      }
    >
      <CheckIcon color="primary" />
    </Tooltip>
  );
};

const CargoStop = ({ stop }: { stop: RouteCargoStop }) => {
  const { t } = useTranslation(["types"]);
  const showCargoModal = useShowCargoModal();
  const dispatch = useAppDispatch();
  const hideLoadmeters = useSelector(selectHideLoadmeters);
  const clientForCargo = useSelector(selectClientForCargo(stop.cargoId));
  const l10n = useL10n();
  const handleClickOpen = () => {
    showCargoModal(stop.cargoId);
  };

  const mouseEnterCargo = () => {
    dispatch(setHoveredCargoId(stop.cargoId));
  };

  const mouseLeaveCargo = () => {
    dispatch(setHoveredCargoId(undefined));
  };

  const cargoStop = stop.cargoStop;
  const place = cargoStop.place;
  const placeName = place?.placeName;

  const dimensions = useMemo(() => {
    return [cargoStop.height, cargoStop.width, cargoStop.length]
      .filter((d) => !!d)
      .join("x");
  }, [cargoStop]);
  const { hoveredCargoId } = useSelector((state: RootState) => state.cargo);

  const useActivities = useFeature(
    "use-only-activities__dispatcher-driver-sessions"
  );
  const completedStop = useSelector(selectCompletedStop(stop.cargoStop.id));
  const completedStopFromActivities = useSelector(
    selectCompletedStopFromActivities(stop.cargoStop.id)
  );
  const compStop = useActivities ? completedStopFromActivities : completedStop;
  const departedStop = useSelector(selectDepartedStop(stop.cargoStop.id));
  const departedStopFromActivities = useSelector(
    selectDepartedStopFromActivities(stop.cargoStop.id)
  );
  const depStop = useActivities ? departedStopFromActivities : departedStop;

  let completedOrDepartedStop;
  if (compStop) {
    completedOrDepartedStop = {
      stopId: compStop.stopId,
      date: compStop.completedAt,
      driverId: compStop.driverId,
    };
  } else if (depStop) {
    completedOrDepartedStop = {
      stopId: depStop.stopId,
      date: depStop.departedAt,
      driverId: depStop.driverId,
    };
  }

  const status = useSelector(selectRouteStopStatus(stop.cargoStop.id));

  return (
    <RouteStopContainer
      onMouseLeave={mouseLeaveCargo}
      onMouseOver={mouseEnterCargo}
      sx={{
        backgroundColor:
          stop.cargoId === hoveredCargoId
            ? "primary.lightened"
            : status === "complete"
              ? "secondary.lightened"
              : status === "in-progress"
                ? "var(--cargo-stop-in-progress)"
                : "",
      }}
    >
      <Grid item xs={1} sx={{ position: "relative", verticalAlign: "middle" }}>
        <CompletionIndicator
          routeStop={stop}
          completedStop={completedOrDepartedStop}
        />
      </Grid>

      {place && (
        <Grid item xs={3}>
          <Typography variant="body2">
            {place.country}-{place.postcode}
          </Typography>
          <Typography variant="body2" color="textSecondary" noWrap>
            {place.city}
          </Typography>
        </Grid>
      )}

      <Grid item xs={3}>
        <Typography variant="body2" color="textSecondary">
          {cargoStop.date && formatCustomDateString(cargoStop.date, "dd/MM")}{" "}
          {cargoStop.time} {cargoStop.fix ? "(FIX)" : ""}
        </Typography>
        {placeName && (
          <Typography variant="body2" color="textSecondary">
            {placeName}
          </Typography>
        )}
      </Grid>

      <Grid item xs={3}>
        <Typography variant="body2" color="textSecondary">
          {!hideLoadmeters && <>{l10n.formatNumber(stop.loadmeters)} ldm </>}
          <Tooltip title={clientForCargo?.client} placement="top">
            <FontAwesomeIcon color="grey" size="sm" icon={faCircleUser} />
          </Tooltip>{" "}
        </Typography>{" "}
        <Typography variant="body2" color="textSecondary">
          {t(`types:cargoTypes.type`, { context: stop.cargoType })}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {dimensions && `(${dimensions})`}
        </Typography>
      </Grid>

      <Grid item xs={1}>
        <Typography variant="body2">
          {l10n.formatNumber(stop.accLoadmeters)}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <IconButton aria-label="open-cargo" onClick={handleClickOpen}>
          <OpenInNewOutlinedIcon fontSize="small" />
        </IconButton>
      </Grid>
    </RouteStopContainer>
  );
};

export default CargoStop;
