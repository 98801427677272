import PlaceSearchWithSavedLocations from "../../add-edit-cargo/PlaceSearchWithSavedLocations/PlaceSearchWithSavedLocations";
import { UFDoraTimeRangePicker } from "../../../Toolkit/DoraTimeRangePicker";
import { UFDoraTextInput } from "../../../Toolkit/DoraTextInput";
import { UFDoraNumberInput } from "../../../Toolkit/DoraNumberInput";
import { UFDoraCheckbox } from "../../../Toolkit/DoraCheckbox";
import { UFDoraSelect } from "../../../Toolkit/DoraSelect";
import { UFDoraDatePicker } from "../../../Toolkit/DoraDatePicker";
import { loadDocuments } from "../../../../ducks/data/stop-documents";
import {
  faCircleUser,
  // faComment,
  faNoteSticky,
  faTrashAlt,
} from "@fortawesome/free-regular-svg-icons";
import {
  faGauge,
  faPhone,
  faPlus,
  faTag,
  // faTag,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import DoraButton from "../../../Toolkit/DoraButton";
import "./StopEdit.scss";
import { Fragment, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AddressChange } from "../../add-edit-cargo/NewPlaceSearch";
import ManageFavoritesAndTerminalsDialog from "../../ManageFavoritesAndTerminalsDialog";
import { useFieldArray, useFormContext } from "react-hook-form";
import { get as lodashGet } from "lodash";
import GenericPromptDialog from "../../GenericPromptDialog/GenericPromptDialog";
import { getUnits } from "../../../../helpers/types";
import { useTranslation } from "react-i18next";
import { nanoid } from "nanoid";
import DoraLinkButton from "../../../Toolkit/DoraLinkButton";
import { StopPlace } from "../../../../ducks/app/cargo-and-template-shared";
import { focusOnLocationSearchInput } from "./helpers";
import { useFeature } from "../../../../hooks";
import Dropzone from "react-dropzone";
import StopDocumentsList from "./StopDocumentsList";
import { useAppDispatch, useSelector } from "../../../../redux-store";
import axios from "../../../../axios";
import { notify } from "../../../../ducks/notifications";
import { CloseButton } from "../../../dispatching-planning/common/close-button";
import { selectHideLoadmeters } from "../../../../ducks/data/org-settings/selectors";
import { CopyIconButton } from "../../../buttons";
import { timeRangeMin5MinutesErr } from "../create-edit-shipment-validation-schema";

type StopEditProps = {
  disableAccumulatedInputs?: boolean;
  isPickup?: boolean;
  isSingleStop?: boolean;

  // useForms inject
  relativeFormPath: string;
  errors: any;
};

const StopEdit = (props: StopEditProps) => {
  const [
    shouldShowConfirmDeleteUnitsDialog,
    setShouldShowConfirmDeleteUnitsDialog,
  ] = useState(false);
  const [
    shouldShowConfirmDeleteMeasurementsDialog,
    setShouldShowConfirmDeleteMeasurementsDialog,
  ] = useState(false);
  const [showManageSavedLocations, setShowManageSavedLocations] =
    useState(false);
  const [
    measurementsDisplayButtonEnabled,
    setMeasurementsDisplayButtonEnabled,
  ] = useState(false);
  const areStopsDocumentsEnabled = useFeature("stops-documents");
  const liteVersion = useFeature("lite-version");
  const {
    isPickup,
    disableAccumulatedInputs,
    errors,
    relativeFormPath,
    isSingleStop,
  } = props;
  const { watch, setFocus, register, control, getValues, setValue } =
    useFormContext();
  const { t } = useTranslation(["types", "shipmentModal", "components"]);
  const {
    fields: units,
    append: appendField,
    remove: removeField,
  } = useFieldArray({
    control,
    name: `${relativeFormPath}.pieces`,
  });
  const isCMRTagEnabled = useFeature("cmr-tag");
  const stopId = getValues(`${relativeFormPath}.id`);
  const [documentsChangeInProgress, setDocumentsChangeInProgress] =
    useState(false);
  const dispatch = useAppDispatch();
  const hideLoadmeters = useSelector(selectHideLoadmeters);

  const unitsTypes = useMemo(
    () =>
      getUnits().map(({ id }) => ({
        value: id,
        label: t("types:unitTypes.unit", { context: id }),
      })),
    [t]
  );

  const onAddUnitsButtonClick = () => {
    appendField({ exchange: false });
    setTimeout(() => {
      setFocus(`${relativeFormPath}.pieces[${units.length}].quantity`);
    }, 50);
  };

  const onDeleteUnitsClick = () => {
    setShouldShowConfirmDeleteUnitsDialog(true);
  };

  const onConfirmDeleteUnitsClick = () => {
    for (let i = 0; i < units.length; i++) {
      removeField(0);
    }
    setShouldShowConfirmDeleteUnitsDialog(false);
  };

  const onDeleteUnitClick = (index: number) => () => {
    removeField(index);
  };

  const onMeasurementsButtonClick = () => {
    setMeasurementsDisplayButtonEnabled(true);
    setTimeout(() => setFocus(`${relativeFormPath}.colli`), 50);
  };

  const onConfirmDeleteMeasurementsClick = () => {
    setValue(`${relativeFormPath}.colli`, "");
    setValue(`${relativeFormPath}.weight`, "");
    setValue(`${relativeFormPath}.length`, "");
    setValue(`${relativeFormPath}.width`, "");
    setValue(`${relativeFormPath}.height`, "");
    setValue(`${relativeFormPath}.cubicMeters`, "");
    setShouldShowConfirmDeleteMeasurementsDialog(false);
    setMeasurementsDisplayButtonEnabled(false);
  };

  const onDeleteMeasurementsClick = () => {
    setShouldShowConfirmDeleteMeasurementsDialog(true);
  };

  const onPlaceSelected = (p: null | AddressChange) => {
    const place: StopPlace | null = p
      ? {
          placeName: p.placeName || p.place || "",
          postcode: p.postcode,
          city: p.city,
          country: p.country,
          address: p.address,
          coord: p.coord,
        }
      : null;
    setValue(`${relativeFormPath}.place`, place);
    const dateInput = document.querySelector(`#${dateWrapperId} input`) as any;
    if (dateInput) {
      dateInput.focus();
    }
  };

  const onUploadDocumentsClick = async (acceptedFiles: File[]) => {
    if (!acceptedFiles.length) {
      // this only happens when a drag drop operation is done with an unaccepted file type
      dispatch(
        notify({
          message: t(
            `shipmentModal:cards.documents.notificationErrorNotAccepted`
          ),
          type: "error",
        })
      );
      return;
    }
    setDocumentsChangeInProgress(true);
    const formData = new FormData();
    for (let f of acceptedFiles) {
      formData.append("files", new Blob([f], { type: f.type }), f.name);
    }
    await axios
      .post(`/api/stops/${stopId}/documents`, formData)
      .then(() => {
        dispatch(loadDocuments(stopId as string));
        dispatch(
          notify({
            message: t(`shipmentModal:cards.documents.notificationSuccess`, {
              count: acceptedFiles.length,
            }),
            type: "success",
          })
        );
      })
      .catch((err) => {
        console.error(err);
        dispatch(
          notify({
            message: t(`shipmentModal:cards.documents.notificationError`, {
              count: acceptedFiles.length,
            }),
            type: "error",
          })
        );
      })
      .finally(() => {
        setDocumentsChangeInProgress(false);
      });
  };

  const placeWatch = watch(`${relativeFormPath}.place`);
  const unitsWatch = watch(`${relativeFormPath}.pieces`);

  const onChangeAddressClick = () => {
    setValue(`${relativeFormPath}.place`, null);
    focusOnLocationSearchInput(`${relativeFormPath}`);
  };

  const onManageLocationsClick = () => {
    setShowManageSavedLocations(true);
  };

  const currentStopErrors = lodashGet(errors, relativeFormPath) || {};

  const dateId = useMemo(() => nanoid(), []);
  const dateWrapperId = useMemo(() => "id-" + nanoid(), []);
  const tagId = useMemo(() => nanoid(), []);
  // const driverNoteId = useMemo(() => nanoid(), []);
  const lmId = useMemo(() => nanoid(), []);
  const colliId = useMemo(() => nanoid(), []);
  const weightId = useMemo(() => nanoid(), []);
  const lengthId = useMemo(() => nanoid(), []);
  const widthId = useMemo(() => nanoid(), []);
  const cubicMetersId = useMemo(() => nanoid(), []);
  const heightId = useMemo(() => nanoid(), []);
  const contactId = useMemo(() => nanoid(), []);
  const phoneId = useMemo(() => nanoid(), []);
  const loadingRefId = useMemo(() => nanoid(), []);
  const searchId = useMemo(() => nanoid(), []);
  const fixId = useMemo(() => nanoid(), []);

  const shouldShowMeasurements = !!(
    measurementsDisplayButtonEnabled ||
    Number(watch(`${relativeFormPath}.colli`)) ||
    Number(watch(`${relativeFormPath}.weight`)) ||
    Number(watch(`${relativeFormPath}.length`)) ||
    Number(watch(`${relativeFormPath}.width`)) ||
    Number(watch(`${relativeFormPath}.height`)) ||
    Number(watch(`${relativeFormPath}.cubicMeters`))
  );

  const fullAddress = `${
    watch(`${relativeFormPath}.place.placeName`) &&
    `${watch(`${relativeFormPath}.place.placeName`)}, `
  } ${
    watch(`${relativeFormPath}.place.address`) &&
    `${watch(`${relativeFormPath}.place.address`)}, `
  } ${watch(`${relativeFormPath}.place.postcode`)} ${watch(
    `${relativeFormPath}.place.city`
  )}`;

  return (
    <>
      {shouldShowConfirmDeleteMeasurementsDialog && (
        <GenericPromptDialog
          title={t("shipmentModal:removeMeasurementsModal.title")}
          text={t("shipmentModal:removeMeasurementsModal.description")}
          continueText={t("components:buttonLabels.remove")}
          continueButtonColor={"error"}
          onCancelClick={() =>
            setShouldShowConfirmDeleteMeasurementsDialog(false)
          }
          onContinueClick={onConfirmDeleteMeasurementsClick}
        />
      )}
      {shouldShowConfirmDeleteUnitsDialog && (
        <GenericPromptDialog
          title={t("shipmentModal:removeUnitsModal.title")}
          text={t("shipmentModal:removeUnitsModal.description")}
          continueText={t("components:buttonLabels.remove")}
          continueButtonColor={"error"}
          onCancelClick={() => setShouldShowConfirmDeleteUnitsDialog(false)}
          onContinueClick={onConfirmDeleteUnitsClick}
        />
      )}
      {showManageSavedLocations && (
        <ManageFavoritesAndTerminalsDialog
          onClose={() => setShowManageSavedLocations(false)}
        />
      )}
      <div className="shipment-cargo-form">
        {placeWatch ? (
          <div className="shipment-cargo-form__place">
            <div className="flex justify-between items-center">
              <div className="flex gap-2">
                {isPickup && !isSingleStop
                  ? t("shipmentModal:inputs.searchLoadingPlaceLabel")
                  : isSingleStop
                    ? t("shipmentModal:inputs.searchPlaceLabel")
                    : t("shipmentModal:inputs.searchUnloadingPlaceLabel")}
                <CopyIconButton
                  label={fullAddress}
                  title={t("components:buttonLabels.copyFullAddress")}
                  noPadding
                />
              </div>
              <div>
                <DoraLinkButton onClick={onManageLocationsClick}>
                  {t("shipmentModal:inputs.searchPlaceManage")}
                </DoraLinkButton>
              </div>
            </div>
            <div>
              <div style={{ position: "relative" }}>
                <UFDoraTextInput
                  register={register(`${relativeFormPath}.place.placeName`)}
                  placeholder={t(
                    "shipmentModal:inputs.searchPlacePlaceNamePlaceholder"
                  )}
                />
                <div style={{ position: "absolute", top: 8, right: 12 }}>
                  <CloseButton onClick={onChangeAddressClick} />
                </div>
              </div>
            </div>
            <div>
              <UFDoraTextInput
                register={register(`${relativeFormPath}.place.address`)}
                placeholder={t(
                  "shipmentModal:inputs.searchPlaceAddressPlaceholder"
                )}
              />
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 3fr 1fr",
                gap: 8,
              }}
            >
              <div>
                <UFDoraTextInput
                  disabled
                  register={register(`${relativeFormPath}.place.postcode`)}
                  placeholder={t(
                    "shipmentModal:inputs.searchPlaceZipPlaceholder"
                  )}
                />
              </div>
              <div>
                <UFDoraTextInput
                  disabled
                  register={register(`${relativeFormPath}.place.city`)}
                  placeholder={t(
                    "shipmentModal:inputs.searchPlaceCityPlaceholder"
                  )}
                />
              </div>
              <div>
                <UFDoraTextInput
                  disabled
                  register={register(`${relativeFormPath}.place.country`)}
                  placeholder={t(
                    "shipmentModal:inputs.searchPlaceCountryPlaceholder"
                  )}
                />
              </div>
            </div>
          </div>
        ) : (
          <div
            id={"search-wrapper-" + relativeFormPath}
            className="shipment-cargo-form__place-search"
          >
            <div className="flex justify-between items-center">
              <label htmlFor={searchId}>
                {t("shipmentModal:inputs.searchPlaceLabel2")}
                <span aria-hidden>*</span>
              </label>
              <div>
                <DoraLinkButton onClick={onManageLocationsClick}>
                  {t("shipmentModal:inputs.searchPlaceManage")}
                </DoraLinkButton>
              </div>
            </div>
            <PlaceSearchWithSavedLocations
              id={searchId}
              noLabel
              onPlaceSelected={onPlaceSelected}
              placeError={currentStopErrors.place}
            />
            {currentStopErrors.place && (
              <div className="shipment-cargo-form__error">
                {t(
                  `shipmentModal:errors.${currentStopErrors.place.message}` as any
                )}
              </div>
            )}
          </div>
        )}
        <div className="shipment-cargo-form__input-list">
          <div className="shipment-cargo-form__input-list-row">
            <label className="shipment-cargo-form__input-list-row-label">
              {t("shipmentModal:inputs.openingTimeLabel")}
            </label>
            <div className="flex-grow">
              <div
                className="flex items-center justify-between"
                style={{ gap: 12 }}
              >
                <div id={dateWrapperId} className="flex-grow">
                  <UFDoraDatePicker
                    id={dateId}
                    name={`${relativeFormPath}.date`}
                    control={control}
                  />
                </div>
                <div className="flex-grow">
                  <UFDoraTimeRangePicker
                    name={`${relativeFormPath}.openingTime`}
                    getValues={getValues}
                    setValue={setValue}
                  />
                </div>
                <div className="shipment-cargo-form__fix-input-wrapper">
                  <label
                    className="shipment-cargo-form__fix-text"
                    htmlFor={fixId}
                  >
                    {t("shipmentModal:inputs.fixLabel")}
                  </label>
                  <UFDoraCheckbox
                    id={fixId}
                    className="shipment-cargo-form__fix-input"
                    register={register(`${relativeFormPath}.fix`)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="shipment-cargo-form__input-list-row">
            <label
              className="shipment-cargo-form__input-list-row-label"
              htmlFor={dateId}
            >
              <i>{t("shipmentModal:inputs.dateTimeLabel")}</i>
              <div className="shipment-cargo-form__info">
                {t("shipmentModal:inputs.dateTimeLabelHelperText")}
              </div>
            </label>
            <div className="flex-grow">
              <div
                className="flex items-center justify-between"
                style={{ gap: 12 }}
              >
                <div className="flex-grow">
                  <UFDoraTimeRangePicker
                    name={`${relativeFormPath}.time`}
                    getValues={getValues}
                    setValue={setValue}
                    error={currentStopErrors.time}
                  />
                  {currentStopErrors.time?.type === timeRangeMin5MinutesErr && (
                    <div className="shipment-cargo-form__error">
                      {t("shipmentModal:errors.timeRangeMin5Minutes")}
                    </div>
                  )}
                </div>
                <div style={{ width: "55%" }}></div>
              </div>
            </div>
          </div>
          <div className="shipment-cargo-form__input-list-row">
            <label
              className="shipment-cargo-form__input-list-row-label"
              htmlFor={contactId}
            >
              {t("shipmentModal:inputs.contactLabel")}
            </label>
            <UFDoraTextInput
              id={contactId}
              register={register(`${relativeFormPath}.contact`)}
              placeholder={t("shipmentModal:inputs.contactPlaceholder2")}
              className="flex-grow"
              icon={faCircleUser}
            />
          </div>
          <div className="shipment-cargo-form__input-list-row">
            <label
              className="shipment-cargo-form__input-list-row-label"
              htmlFor={phoneId}
            >
              {t("shipmentModal:inputs.phoneLabel")}
            </label>
            <UFDoraNumberInput
              id={phoneId}
              register={register(`${relativeFormPath}.contactPhone`)}
              className="flex-grow"
              placeholder={t("shipmentModal:inputs.phonePlaceholder2")}
              icon={faPhone}
            />
          </div>
          <div className="shipment-cargo-form__input-list-row">
            <label
              className="shipment-cargo-form__input-list-row-label"
              htmlFor={loadingRefId}
            >
              {isPickup && !isSingleStop
                ? t("shipmentModal:inputs.loadingRefLabel")
                : isSingleStop
                  ? t("shipmentModal:inputs.refLabel")
                  : t("shipmentModal:inputs.unloadingRefLabel")}
            </label>
            <UFDoraTextInput
              id={loadingRefId}
              className="flex-grow"
              placeholder={t("shipmentModal:inputs.loadingRefPlaceholder")}
              register={register(`${relativeFormPath}.ref`)}
              icon={faNoteSticky}
            />
          </div>
          {isCMRTagEnabled && (
            <div className="shipment-cargo-form__input-list-row">
              <label
                htmlFor={tagId}
                className="shipment-cargo-form__input-list-row-label"
              >
                {t("shipmentModal:inputs.tagLabel")}
              </label>
              <UFDoraTextInput
                id={tagId}
                className="flex-grow"
                placeholder={t("shipmentModal:inputs.tagPlaceholder")}
                register={register(`${relativeFormPath}.tag`)}
                icon={faTag}
              />
            </div>
          )}
          {/*<div className="shipment-cargo-form__input-list-row">*/}
          {/*  <label*/}
          {/*    className="shipment-cargo-form__input-list-row-label"*/}
          {/*    htmlFor={driverNoteId}*/}
          {/*  >*/}
          {/*    Note to driver*/}
          {/*  </label>*/}
          {/*  <UFDoraTextInput*/}
          {/*    id={driverNoteId}*/}
          {/*    register={register(`${relativeFormPath}.note`)}*/}
          {/*    className="flex-grow"*/}
          {/*    placeholder={"Add note"}*/}
          {/*    icon={faComment}*/}
          {/*  />*/}
          {/*</div>*/}
          <div className="shipment-cargo-form__input-list-row">
            {!hideLoadmeters && (
              <>
                <label
                  className="shipment-cargo-form__input-list-row-label"
                  htmlFor={lmId}
                >
                  {t("shipmentModal:inputs.loadMetersLabel")}
                  <span aria-hidden>*</span>
                </label>
                <div className="flex-grow">
                  <UFDoraNumberInput
                    id={lmId}
                    placeholder={t("shipmentModal:inputs.loadMetersLabel")}
                    icon={faGauge}
                    disabled={disableAccumulatedInputs}
                    register={register(`${relativeFormPath}.loadmeters`)}
                    error={currentStopErrors.loadmeters}
                  />
                  {currentStopErrors.lm && (
                    <div className="shipment-cargo-form__error">
                      {currentStopErrors.lm.message}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="shipment-cargo-form__measurement-units">
          <div>
            <div className="shipment-cargo-form__measurement-units-row">
              <label className="shipment-cargo-form__measurement-units-label">
                {t("shipmentModal:inputs.measurementsLabel")}
              </label>

              {shouldShowMeasurements ? (
                <div className="flex justify-end w-full">
                  <DoraLinkButton onClick={onDeleteMeasurementsClick}>
                    {t("components:buttonLabels.delete")}
                  </DoraLinkButton>
                </div>
              ) : (
                <DoraButton
                  onClick={onMeasurementsButtonClick}
                  disabled={disableAccumulatedInputs}
                  variant={"ghost"}
                >
                  <FontAwesomeIcon icon={faPlus} style={{ marginRight: 8 }} />
                  {t("shipmentModal:inputs.addMeasurements")}
                </DoraButton>
              )}
            </div>
            {shouldShowMeasurements && (
              <div className="shipment-cargo-form__measurements-wrapper">
                <div>
                  <label htmlFor={colliId}>
                    {t("shipmentModal:inputs.colli")}
                  </label>
                  <UFDoraNumberInput
                    id={colliId}
                    register={register(`${relativeFormPath}.colli`)}
                    disabled={disableAccumulatedInputs}
                    placeholder={t("shipmentModal:inputs.colli")}
                  />
                </div>
                <div>
                  <label htmlFor={weightId}>
                    {t("shipmentModal:inputs.weightLabel")}
                  </label>
                  <UFDoraNumberInput
                    id={weightId}
                    register={register(`${relativeFormPath}.weight`)}
                    disabled={disableAccumulatedInputs}
                    suffix={"kg"}
                    placeholder={t("shipmentModal:inputs.weightLabel")}
                  />
                </div>
                <div>
                  <label htmlFor={cubicMetersId}>
                    {t("shipmentModal:inputs.cubicMetersLabel")}
                  </label>
                  <UFDoraNumberInput
                    id={cubicMetersId}
                    register={register(`${relativeFormPath}.cubicMeters`)}
                    disabled={disableAccumulatedInputs}
                    suffix={"m³"}
                    placeholder={t("shipmentModal:inputs.cubicMetersLabel")}
                  />
                </div>
                <div>
                  <label htmlFor={lengthId}>
                    {t("shipmentModal:inputs.lengthLabel")}
                  </label>
                  <UFDoraNumberInput
                    id={lengthId}
                    register={register(`${relativeFormPath}.length`)}
                    suffix={"cm"}
                    placeholder={t("shipmentModal:inputs.lengthLabel")}
                  />
                </div>
                <div>
                  <label htmlFor={widthId}>
                    {t("shipmentModal:inputs.widthLabel")}
                  </label>
                  <UFDoraNumberInput
                    id={widthId}
                    register={register(`${relativeFormPath}.width`)}
                    suffix={"cm"}
                    placeholder={t("shipmentModal:inputs.widthLabel")}
                  />
                </div>
                <div>
                  <label htmlFor={heightId}>
                    {t("shipmentModal:inputs.heightLabel")}
                  </label>
                  <UFDoraNumberInput
                    id={heightId}
                    register={register(`${relativeFormPath}.height`)}
                    suffix={"cm"}
                    placeholder={t("shipmentModal:inputs.heightLabel")}
                  />
                </div>
              </div>
            )}
          </div>
          <div>
            <div className="shipment-cargo-form__measurement-units-row">
              <label className="shipment-cargo-form__measurement-units-label">
                {t("shipmentModal:inputs.units")}
              </label>
              {unitsWatch?.length ? (
                <div className="flex justify-end w-full">
                  <DoraLinkButton
                    onClick={onDeleteUnitsClick}
                    disabled={disableAccumulatedInputs}
                  >
                    {t("components:buttonLabels.delete")}
                  </DoraLinkButton>
                </div>
              ) : (
                <DoraButton
                  variant={"ghost"}
                  disabled={disableAccumulatedInputs}
                  onClick={onAddUnitsButtonClick}
                >
                  <FontAwesomeIcon icon={faPlus} style={{ marginRight: 8 }} />
                  {t("shipmentModal:inputs.addUnits")}
                </DoraButton>
              )}
            </div>
          </div>

          {units.length > 0 && (
            <>
              <div className="shipment-cargo-form__units-table-wrapper">
                <div className="shipment-cargo-form__units-table-header-cell">
                  {t("shipmentModal:inputs.unitsQty")}
                </div>
                <div className="shipment-cargo-form__units-table-header-cell"></div>
                <div className="shipment-cargo-form__units-table-header-cell">
                  {t("shipmentModal:inputs.unitsItem")}
                </div>
                <div className="shipment-cargo-form__units-table-header-cell"></div>

                {unitsWatch.map((_: any, index: any) => {
                  const v = getValues(
                    `${relativeFormPath}.pieces[${index}].unit`
                  );
                  const dropdownKeyForChangeDetection = `dropdown-${index}-${v}`;
                  return (
                    <Fragment key={index}>
                      <div>
                        <UFDoraNumberInput
                          register={register(
                            `${relativeFormPath}.pieces[${index}].quantity`,
                            {
                              valueAsNumber: true,
                            }
                          )}
                          disabled={disableAccumulatedInputs}
                          className="shipment-cargo-units-table__qty"
                          error={currentStopErrors.pieces?.[index]?.quantity}
                        />
                      </div>
                      <div className="flex items-center">
                        <FontAwesomeIcon
                          className="shipment-cargo-units-table__times"
                          icon={faTimes}
                        />
                      </div>
                      <div key={dropdownKeyForChangeDetection}>
                        <UFDoraSelect
                          control={control}
                          name={`${relativeFormPath}.pieces[${index}].unit`}
                          fullWidth
                          className="shipment-cargo-units-table__dropdown"
                          options={unitsTypes}
                          error={currentStopErrors.pieces?.[index]?.type}
                          disabled={disableAccumulatedInputs}
                        />
                      </div>
                      <DoraButton
                        variant="ghost"
                        className="shipment-cargo-units-table__trash-icon"
                        onClick={onDeleteUnitClick(index)}
                        disabled={disableAccumulatedInputs}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </DoraButton>
                    </Fragment>
                  );
                })}
              </div>
              <div>
                <DoraButton
                  variant={"ghost"}
                  onClick={onAddUnitsButtonClick}
                  disabled={disableAccumulatedInputs}
                >
                  <FontAwesomeIcon icon={faPlus} style={{ marginRight: 8 }} />
                  {t("shipmentModal:inputs.addMoreUnits")}
                </DoraButton>
              </div>
            </>
          )}
        </div>
        {!liteVersion && areStopsDocumentsEnabled && (
          <div className="shipment-cargo-form__documents">
            <div className="flex items-center">
              <label>{t("shipmentModal:inputs.documentsLabel")}</label>
              {!!stopId && (
                <Dropzone
                  maxSize={50000000}
                  accept={{
                    "image/png": [".png"],
                    "image/jpeg": [".jpeg", ".jpg"],
                    "application/pdf": [".pdf"],
                  }}
                  onDrop={onUploadDocumentsClick}
                  disabled={documentsChangeInProgress}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <DoraButton
                        variant="ghost"
                        disabled={documentsChangeInProgress}
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          style={{ marginRight: 8 }}
                        />
                        Upload
                      </DoraButton>
                    </div>
                  )}
                </Dropzone>
              )}
            </div>
            {!!stopId ? (
              <StopDocumentsList
                stopId={stopId}
                controlsDisabled={documentsChangeInProgress}
              />
            ) : (
              <div className="shipment-stop-documents__available-later">
                {t("shipmentModal:inputs.documentsStopAvailableLater")}
              </div>
            )}
          </div>
        )}
        <div className="shipment-cargo-form__bottom-border"></div>
      </div>
    </>
  );
};

export default StopEdit;
