import { combineReducers } from "redux";
import cargoDialog from "./cargo-dialog";
import cargoTemplateDialog from "./cargo-template-dialog";
import fleetManagement from "./fleet-management";
import driverDialog from "./driver-dialog";
import trailerDialog from "./trailer-dialog";
import routeInfo from "./route-info";
import userSettings from "./userSettings";
import teamsDialog from "./teams-dialog";
import dispatching from "./dispatching";
import routes from "./routes";
import upcomingPage from "./upcoming-page";
import orderDialog from "./order-dialog";
import businessCentral from "./business-central";
import apiKeys from "./api-keys";
import undispatchedCargosCardsHover from "./undispatched-cargos-cards-hover";

export default combineReducers({
  cargoDialog,
  cargoTemplateDialog,
  fleetManagement,
  driverDialog,
  trailerDialog,
  userSettings,
  routeInfo,
  teamsDialog,
  dispatching,
  routes,
  upcomingPage,
  orderDialog,
  businessCentral,
  apiKeys,
  undispatchedCargosCardsHover,
});
