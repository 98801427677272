import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { matchingsSelector } from "../../../ducks/smart-match/selectors";
import { Radius } from "./radius";
import { SmartMatchingCargo } from "./smart-matching-cargo";
import { UpdateRadiusArgs, updateRouteRadius } from "../../../ducks/routes";
import { getSmartMatches } from "../../../ducks/smart-match";
import { selectRoute } from "../../../ducks/routes/selectors";
import { useAppDispatch } from "../../../redux-store";
import { CargoView } from "../../../ducks/data/cargo-views/types";

interface Props {
  routeId: string;
  smartMatchCargos: CargoView[];
}

export const SmartMatch: FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const route = useSelector(selectRoute(props.routeId));
  const matchings = useSelector(matchingsSelector(props.routeId));

  const handleRadiusChange = async (newRadius: number) => {
    if (route) {
      await dispatch(
        updateRouteRadius({
          routeId: route.id,
          radius: newRadius,
        } as UpdateRadiusArgs)
      );
      dispatch(getSmartMatches(route?.id));
    }
  };

  useEffect(() => {
    if (route) {
      dispatch(getSmartMatches(route?.id));
    }
  }, [route, dispatch]);

  return (
    <>
      <Grid container justifyContent="space-between">
        <Typography variant="h5">Smart match</Typography>
        <Grid item xs={6}>
          <Radius
            key={props.routeId}
            initialState={route?.radius}
            onChange={handleRadiusChange}
          />
        </Grid>
      </Grid>

      {props.smartMatchCargos.map((cargo, index) => (
        <SmartMatchingCargo
          key={cargo.id}
          cargoInfo={cargo}
          matching={matchings.find(
            (matching) => matching.cargo_info_id === cargo.id
          )}
        />
      ))}
    </>
  );
};
