import { ComponentType, PropsWithChildren, useEffect, useState } from "react";

const DelayedRender: ComponentType<PropsWithChildren> = (
  props: PropsWithChildren
) => {
  const [render, setRender] = useState(false);
  useEffect(() => {
    setImmediate(() => setRender(true));
  }, [setRender]);
  return render && props.children ? <>{props.children}</> : null;
};

export default DelayedRender;
