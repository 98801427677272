import { Grid, GridProps } from "@mui/material";
import omit from "lodash/omit";

const RouteStopContainer = (props: GridProps) => {
  return (
    <Grid
      item
      container
      direction="row"
      alignItems="flex-start"
      sx={{
        userSelect: "none",
        cursor: "grab",
        margin: "0.25rem 0",
        padding: "0.5rem",
        backgroundColor: "",
        borderRadius: "0.5rem",
        ...props.sx,
      }}
      {...omit(props, "sx")}
    />
  );
};

export default RouteStopContainer;
