import { Box, SxProps, Theme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { FC, ReactElement } from "react";

interface Props {
  text: string;
  icon: ReactElement;
  onClick?: () => void;
  sx?: SxProps<Theme> | undefined;
}
export const IconButton: FC<Props> = React.memo((props) => {
  return (
    <Box sx={{ cursor: "pointer" }} onClick={props.onClick}>
      <Grid
        container
        wrap="nowrap"
        direction="row"
        sx={{ marginBottom: "16px" }}
      >
        {props.icon}
        <Typography sx={props.sx} variant="body2" pl="8px">
          {props.text}
        </Typography>
      </Grid>
    </Box>
  );
});
