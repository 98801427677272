import { useSelector } from "react-redux";
import { Session } from "../../../ducks/data/driver-sessions/types";
import { selectSessionsForRoute } from "../../../ducks/data/driver-sessions/selectors";
import orderBy from "lodash/orderBy";
import { Avatar, AvatarGroup, Grid, Tooltip, Badge } from "@mui/material";
import { formatDateAndTime } from "../../../helpers/date-helper";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { selectDriver } from "../../../ducks/data/drivers/selectors";
import { selectRouteViewModel } from "../../../ducks/data/route-views/selectors";
import { selectTrailers } from "../../../ducks/data/trailers/selectors";
import { Driver } from "dora-contracts";
import { selectRouteLogin } from "../../../ducks/data/driver-activities/selectors";
import { useFeature } from "../../../hooks";
import { DateTime } from "luxon";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  // "@keyframes ripple": {
  //     "0%": {
  //         transform: "scale(.8)",
  //         opacity: 1,
  //     },
  //     "100%": {
  //         transform: "scale(2.4)",
  //         opacity: 0,
  //     },
  // },
}));

const getDriverInitials = (driver: Driver) =>
  driver.firstName[0] + driver.lastName[0];

const DriverAvatar = ({ driver }: { driver: Driver }) => (
  <Avatar
    alt={`${driver.firstName} ${driver.lastName}`}
    sx={{
      width: 24,
      height: 24,
      fontSize: 14,
      // marginTop: 0.5, //1
    }}
  >
    {getDriverInitials(driver)}
  </Avatar>
);

const DriverAvatarInSession = ({
  driverId,
  session,
}: {
  driverId: string;
  session: {
    loggedInAt: DateTime;
    driverId: string;
    trailerId: string | null;
  } | null;
}) => {
  const driver = useSelector(selectDriver(driverId));
  const trailers = useSelector(selectTrailers);
  const { t } = useTranslation("dispatching");
  let trailerForDriver = null;
  if (driver && session) {
    trailerForDriver = trailers.find((t) => t.id === session!.trailerId);
  }

  return (
    driver &&
    (session?.driverId === driverId ? (
      <Tooltip
        key={driverId}
        title={
          <>
            {t("routeCard.driver")}: {`${driver.firstName} ${driver.lastName}`}
            <br />
            {t("routeCard.loggedIn")}: {formatDateAndTime(session.loggedInAt)}
            {trailerForDriver && (
              <>
                <br />
                {trailerForDriver.number}
              </>
            )}
          </>
        }
      >
        <StyledBadge
          overlap="circular"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          variant="dot"
        >
          <DriverAvatar driver={driver} />
        </StyledBadge>
      </Tooltip>
    ) : (
      <DriverAvatar driver={driver} />
    ))
  );
};

export const RouteDriverSession = ({
  session,
  drivers,
}: {
  session: { loggedInAt: DateTime; driverId: string; trailerId: string | null } | null;
  drivers: string[];
}) => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item>
        <AvatarGroup
          total={drivers.length}
          componentsProps={{
            additionalAvatar: {
              sx: {
                // backgroundColor: "green",
                width: 24,
                height: 24,
                fontSize: 14,
                // marginTop: 0.5, //1
              },
            },
          }}
        >
          {drivers.map((driverId) => (
            <DriverAvatarInSession
              key={driverId}
              driverId={driverId}
              session={session}
            />
          ))}
        </AvatarGroup>
        <div
          style={{
            fontSize: 14,
            lineHeight: "14px",
            marginTop: 2,
          }}
        >
          {drivers.map((d) => (
            <DriverName key={d} driverId={d} />
          ))}
        </div>
      </Grid>
    </Grid>
  );
};

const DriverName = ({ driverId }: { driverId: string }) => {
  const driver = useSelector(selectDriver(driverId));
  return (
    <div className="text-end">
      {driver ? `${driver.firstName} ${driver.lastName.charAt(0)}.` : ""}
    </div>
  );
};

const RouteDrivers = ({ routeId }: { routeId: string }) => {
  const drivers = useSelector(selectRouteViewModel(routeId))?.drivers || [];

  const useActivities = useFeature(
    "use-only-activities__dispatcher-driver-sessions"
  );
  const routeSessions = useSelector(selectSessionsForRoute(routeId));
  const currentSession =
    routeSessions && routeSessions.length
      ? orderBy(routeSessions, "loggedInAt", "desc")[0]
      : null;
  const driverLoginActivity = useSelector(selectRouteLogin(routeId));
  const sessionData = useActivities ? driverLoginActivity : currentSession;
  return <RouteDriverSession session={sessionData} drivers={drivers} />;
};

export default RouteDrivers;
