import ArchiveIcon from "@mui/icons-material/Archive";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import React, { useState } from "react";
import { unarchiveRoute } from "../../../ducks/routes";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../redux-store";
import useNavigateToRoute from "../../../hooks/useNavigateToRoute";

export default function UnarchiveRouteButton(props: any) {
  const navigateToRoute = useNavigateToRoute();
  const { t } = useTranslation(["components", "dispatching"]);
  const { route_id } = props;
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);

  const handleUnarchiveRoute = (route_id: any) => {
    dispatch(
      unarchiveRoute({
        id: route_id,
      })
    ).then(() => {
      navigateToRoute(null);
    });
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<ArchiveIcon />}
        onClick={handleClick}
      >
        {t("dispatching:openRouteView.recoverRoute")}
      </Button>
      <Popper open={open} anchorEl={anchorEl}>
        <Paper>
          <DialogTitle>
            {" "}
            {t("dispatching:openRouteView.recoverRoute")}
          </DialogTitle>
          <DialogContent>
            {t("dispatching:openRouteView.youAreRecoveringARoute")}
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="primary" onClick={handleClick}>
              {t("components:buttonLabels.cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={(e: any) => handleUnarchiveRoute(route_id)}
            >
              {t("components:buttonLabels.confirm")}
            </Button>
          </DialogActions>
        </Paper>
      </Popper>
    </>
  );
}
