import React from "react";
import { Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { selectTrailer } from "../../../ducks/data/trailers/selectors";
import { useTranslation } from "react-i18next";

interface Props {
  trailerId: string | null;
  children: React.ReactNode;
}

const TrailerTooltipTitle = ({ trailerId }: { trailerId?: string | null }) => {
  const trailer = useSelector(selectTrailer(trailerId));
  const { t } = useTranslation(["translation", "types", "cargo"]);

  if (!trailer) {
    return null;
  }

  const cargoTypes = trailer?.qualities
    ?.filter(
      (i: any) =>
        t(`types:cargoTypes.type`, {
          context: i,
        }) !== ""
    )
    .map((i: any) =>
      t(`types:cargoTypes.type`, {
        context: i,
      })
    )
    .join(", ");

  const trailerQualities = trailer?.qualities
    ?.filter(
      (i: any) =>
        t(`types:trailerQualities.type`, {
          context: i,
        }) !== ""
    )
    .map((i: any) =>
      t(`types:trailerQualities.type`, {
        context: i,
      })
    )
    .join(", ");

  return (
    <div>
      {t("cargo:details.type")}:{" "}
      {t(`types:trailerTypes.type`, {
        context: trailer.type,
      })}
      <br />
      {(cargoTypes || trailerQualities) && `${t("cargo:details.qualities")}: `}
      {cargoTypes &&
        trailerQualities &&
        cargoTypes.concat(", ", trailerQualities)}
      {cargoTypes && !trailerQualities && cargoTypes}
      {trailerQualities && !cargoTypes && trailerQualities}
      {(cargoTypes || trailerQualities) && <br />}
      {t("translation:measurements.abbrev.loadmeter")}: {trailer.lm}
      <br />
      {t("translation:measurements.height")}: {trailer.height} <br />
      {t("translation:measurements.width")}: {trailer.width} <br />
      {t("translation:measurements.length")}: {trailer.length}
      <br />
      {t("translation:measurements.weight")}: {trailer.weight}
      <br />
      {trailer.note && `${t("cargo:details.obs")}: ${trailer.note}`}
    </div>
  );
};

export const TrailerHover = (props: Props) => {
  return (
    <Tooltip
      title={<TrailerTooltipTitle trailerId={props.trailerId} />}
      placement="left-start"
    >
      {props.children as any}
    </Tooltip>
  );
};
