import { Box, Skeleton, Typography, Grid } from "@mui/material";

export const LogSkeleton = (props: { label: string }) => {
  return (
    <Box p={3}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        pb={2}
      >
        <Grid item xs={2}>
          <Skeleton
            animation={false}
            variant="circular"
            width={40}
            height={40}
          />
        </Grid>
        <Grid item xs={10}>
          <Skeleton animation="wave" width="100%" height="100%" />
        </Grid>
      </Grid>
      <Typography>{props.label}</Typography>
    </Box>
  );
};
