import React, { FC, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import { BootstrapDialog } from "../../modals/common/modal-styling";
import { updateRouteNote } from "../../../ducks/routes";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../redux-store";
import { selectRouteViewModel } from "../../../ducks/data/route-views/selectors";

interface Props {
  routeId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const EditRouteNote: FC<Props> = React.memo((props) => {
  const { t } = useTranslation("dispatching");
  const dispatch = useAppDispatch();
  const route = useSelector(selectRouteViewModel(props.routeId));

  const [note, setNote] = useState<string | undefined>(
    route && route.note ? route.note : ""
  );

  const handleClose = () => {
    if (route && route.note !== note) {
      dispatch(updateRouteNote({ ...route, note }));
    }

    props.onClose();
  };

  useEffect(() => {
    setNote(route && route.note ? route.note : "");
  }, [route]);

  const noteChange = (event: any) => {
    setNote(event.target.value);
  };

  return (
    <BootstrapDialog
      maxWidth="sm"
      fullWidth
      scroll="paper"
      aria-labelledby="add-cargo-details"
      open={props.isOpen}
      onBackdropClick={handleClose}
    >
      <TextField
        id="outlined-textarea"
        label={t("openRouteView.actions.note")}
        value={note}
        multiline
        rows={6}
        onChange={noteChange}
      />
    </BootstrapDialog>
  );
});
