import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { EditIconButton } from "../../buttons";
import { ShareOutlined } from "@mui/icons-material";
import { WaypointData } from "../../../ducks/app/route-info/stops/types";
import RouteStopContainer from "./RouteStopContainer";

const RouteWaypoint = ({
  waypoint,
  onEditButtonClick,
}: {
  waypoint: WaypointData;
  onEditButtonClick: (waypoint: WaypointData) => void;
}) => {
  return (
    <RouteStopContainer>
      <Grid item xs={1} sx={{ position: "relative", verticalAlign: "middle" }}>
        <ShareOutlined color="disabled" />
      </Grid>
      <Grid item xs={3}>
        {waypoint.place.country && waypoint.place.postcode && (
          <Typography variant="body2">
            {waypoint.place.country}-{waypoint.place.postcode}
          </Typography>
        )}
        <Typography variant="body2" color="textSecondary">
          {waypoint.place.city}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2" color="textSecondary">
          {waypoint.place.placeName}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2" color="textSecondary">
          {waypoint.note}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="body2" color="textSecondary">
          -
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <EditIconButton onClick={() => onEditButtonClick(waypoint)} />
      </Grid>
    </RouteStopContainer>
  );
};

export default RouteWaypoint;
