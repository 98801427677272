import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RouteInfo } from "./route-info";
import { SmartMatch } from "./smart-match";
import { useAppDispatch } from "../../../redux-store";
import * as selectors from "../../../ducks/app/route-info/stops/selectors";
import * as actions from "../../../ducks/app/route-info/stops";
import FeatureToggle from "../../FeatureToggle";
import { CargoView } from "../../../ducks/data/cargo-views/types";

export const cargoInfoDroppableId = "cargo";
export const smartMatchDroppableId = "smart";

const RouteStops = ({ routeId }: { routeId: string }) => {
  const dispatch = useAppDispatch();
  const stopsAndWaypoints = useSelector(selectors.selectRouteStopsAndWaypoints);
  const smartMatchesState: CargoView[] = [];
  useEffect(() => {
    dispatch(actions.loadRoute(routeId));
  }, [dispatch, routeId]);
  return (
      stopsAndWaypoints && (
      <>
        <RouteInfo routeId={routeId} stopsAndWaypoints={stopsAndWaypoints} />

        <hr />
        <FeatureToggle feature="smartMatch">
          <SmartMatch routeId={routeId} smartMatchCargos={smartMatchesState} />
        </FeatureToggle>
      </>
    )
  );
};

export default RouteStops;
