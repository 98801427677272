import { useMemo } from "react";
import { useTeams } from "../ducks/data/teams/hooks";
import GenericAutocomplete from "./GenericAutocomplete";
import { useTranslation } from "react-i18next";

const TeamSelector = (props: {
  value: string;
  onChange: (teamId: string) => void;
}) => {
  const { t } = useTranslation("dispatching");
  const teams = useTeams();
  const teamOptions = useMemo(
    () => teams.map(({ id, name }) => ({ id, label: name })),
    [teams]
  );
  return (
    <GenericAutocomplete
      options={teamOptions}
      value={props.value}
      label={t("routesTopBar.assignedTeam")}
      onChange={(teamId) => {
        if (teamId) props.onChange(teamId);
      }}
    />
  );
};

export default TeamSelector;
