import { useEffect, useState } from "react";
import {
  selectInitialFormData,
  selectIsOpen,
} from "../../../ducks/app/trailer-dialog/selectors";
import { useAppDispatch, useSelector } from "../../../redux-store";
import { selectTrailers } from "../../../ducks/app/fleet-management/selectors";
import { loadTrailers } from "../../../ducks/data/trailers";
import TrailerVehicleDialog from "./TrailerVehicleDialog";
import { useTranslation } from "react-i18next";
import * as dataActions from "../../../ducks/data/trailers";
import { FormData } from "../../../ducks/app/trailer-dialog";
import { Trailer } from "../../../ducks/data/trailers/types";
import { loadArchivedTrailers } from "../../../ducks/data/archived-trailers";
import { selectArchivedTrailers } from "../../../ducks/data/archived-trailers/selectors";

export const AddTrailerDialog = (props: {
  trailers: Trailer[];
  trailerValues: FormData;
  // setTrailerValues: (values: any) => void;
}) => {
  const { t } = useTranslation("modals");
  const dispatch = useAppDispatch();
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = (data: FormData) => {
    setSubmitting(true);
    dispatch(dataActions.addTrailer(data)).finally(() => {
      setSubmitting(false);
    });
  };

  return (
    <TrailerVehicleDialog
      title={
        props.trailerValues.powered
          ? t("addNewTrailer.addNewVehicle")
          : t("addNewTrailer.addNewTrailer")
      }
      trailers={props.trailers}
      initialData={props.trailerValues}
      submitting={submitting}
      onSubmit={onSubmit}
      saveButtonText={t("addNewTrailer.actions.buttonAdd")}
    />
  );
};

type FormDataWithId = Omit<FormData, "id"> & { id: string };
interface DummyEditTrailerProps {
  trailers: Trailer[];
  initialData: FormDataWithId;
}

const EditTrailerDialog = (props: DummyEditTrailerProps) => {
  const { t } = useTranslation("modals");
  const { trailers, initialData } = props;
  const dispatch = useAppDispatch();

  const onSubmit = async (values: FormDataWithId) => {
    const type = values.type;
    if (type !== null) {
      dispatch(dataActions.updateTrailer({ ...values, type }));
    }
  };

  return (
    <TrailerVehicleDialog
      onSubmit={onSubmit}
      initialData={initialData}
      trailers={trailers}
      editingExisting
      title={
        props.initialData.powered
          ? t("addNewTrailer.editVehicle")
          : t("addNewTrailer.editTrailer")
      }
      saveButtonText={t("addNewTrailer.actions.buttonSave")}
    />
  );
};

const OpenTrailerDialog = () => {
  const initialData = useSelector(selectInitialFormData);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadTrailers());
    dispatch(loadArchivedTrailers());
  }, [dispatch]);
  const trailers = useSelector(selectTrailers);
  const archivedTrailers = useSelector(selectArchivedTrailers);
  const allTrailers = [...trailers, ...archivedTrailers];
  if (initialData === null) {
    return null;
  }
  const id = initialData.id;
  return id ? (
    <EditTrailerDialog
      trailers={allTrailers}
      initialData={{ ...initialData, id }}
    />
  ) : (
    <AddTrailerDialog trailers={allTrailers} trailerValues={initialData} />
  );
};

const TrailerDialog = () => {
  const open = useSelector(selectIsOpen);
  return open ? <OpenTrailerDialog /> : null;
};

export default TrailerDialog;
