import React from "react";
import {
  // List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
// import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { CargoEvent } from "../../../ducks/data/cargo-events/types";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import RoomIcon from "@mui/icons-material/Room";
import NoteIcon from "@mui/icons-material/Note";
import ScaleIcon from "@mui/icons-material/Scale";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import ArticleIcon from "@mui/icons-material/Article";
import DownloadIcon from "@mui/icons-material/Download";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SportsScoreOutlinedIcon from "@mui/icons-material/SportsScoreOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/free-regular-svg-icons";
import { selectDriver } from "../../../ducks/data/drivers/selectors";
import { useSelector } from "react-redux";
import { formatDateAndTimeDayTwoDigits } from "../../../helpers/date-helper";
import { useL10n } from "../../../l10n";
import PlaceIcon from "@mui/icons-material/Place";
import { Tooltip } from "@mui/material";
import { selectClientForCargo } from "../../../ducks/data/clients/selectors";
// import { selectCargoEvents } from "../../../ducks/data/cargo-events/selectors";
// import { useAppDispatch } from "../../../redux-store";
// import { loadCargoEvents } from "../../../ducks/data/cargo-events";
import { SvgIconComponent } from "@mui/icons-material";

const fullName = (driver: null | { firstName?: string; lastName?: string }) =>
  driver ? `${driver?.firstName || ""} ${driver?.lastName || ""}`.trim() : "";

const Position = ({
  lon,
  lat,
  accuracy,
}: {
  lon: number;
  lat: number;
  accuracy: number;
}) => {
  const l10n = useL10n();
  const { t } = useTranslation("dispatching");
  const distance =
    accuracy > 1000
      ? l10n.formatNumber(Math.ceil(accuracy / 1000)) + "km."
      : l10n.formatNumber(accuracy) + "m.";
  const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    `${lat},${lon}`
  )}`;
  return (
    <Tooltip title={t("driverEvents.accuracyWithin", { distance })}>
      <IconButton
        aria-label="vis på kort"
        component="a"
        href={url}
        target="_blank"
        rel="noreferrer"
      >
        <PlaceIcon />
      </IconButton>
    </Tooltip>
  );
};

type OnShowCargo = (cargoId: string) => void;

const EventItemLastEdited = ({
  lastEdited,
}: {
  lastEdited: {
    at: string;
    by: string;
    value: string;
  };
}) => {
  const { t } = useTranslation("dispatching");
  const editedBy = useSelector(selectDriver(lastEdited.by));
  return (
    <>
      <div>
        {t("driverEvents.lastEdited", {
          by: fullName(editedBy),
          value: formatDateAndTimeDayTwoDigits(lastEdited.value),
        })}
      </div>
    </>
  );
};

const EventItem = ({
  onShowCargo,
  event,
  cargoId,
  icon,
  children,
  secondaryAction,
}: {
  onShowCargo?: OnShowCargo;
  event: CargoEvent;
  cargoId: string | null;
  icon: SvgIconComponent;
  children: React.ReactNode;
  secondaryAction?: React.ReactNode;
}) => {
  const { t } = useTranslation("dispatching");
  const driver = useSelector(selectDriver(event.createdBy.driverId));
  const timeAndDriver = t("driverEvents.atAndBy", {
    time: formatDateAndTimeDayTwoDigits(event.time),
    driver: fullName(driver),
  });
  const { position } = event;
  const Icon = icon;
  const plannedLocation =
    "plannedLocation" in event ? event.plannedLocation : null;
  return (
    <ListItem
      style={{ paddingLeft: 0 }}
      secondaryAction={
        <>
          {secondaryAction}
          {position && <Position {...position} />}
        </>
      }
    >
      <ListItemIcon style={{ minWidth: "initial", paddingRight: 12 }}>
        <Icon fontSize={"small"} />
      </ListItemIcon>
      <ListItemText
        secondary={
          <>
            {timeAndDriver}
            {plannedLocation && (
              <>
                <br />
                {`${plannedLocation.place ? `${plannedLocation.place}, ` : ""}
                                ${plannedLocation.city || ""}`}
              </>
            )}
            {onShowCargo && "cargoId" in event
              ? cargoId && <CargoClient cargoId={cargoId} />
              : null}
            {(event as any).lastEdited ? (
              <EventItemLastEdited lastEdited={(event as any).lastEdited} />
            ) : null}
            {onShowCargo && "cargoId" in event ? (
              <div>
                <Link onClick={() => onShowCargo(event.cargoId)}>
                  {t("driverEvents.showCargoLinkText")}
                </Link>
              </div>
            ) : null}
          </>
        }
      >
        {children}
      </ListItemText>
    </ListItem>
  );
};

export const DriverEventItem = (props: {
  event: CargoEvent;
  cargoId: string | null;
  onShowCargo?: OnShowCargo;
}) => {
  const l10n = useL10n();
  const { t } = useTranslation("dispatching");
  const { event } = props;
  switch (event.type) {
    case "CARGO_COLLECTED":
      return (
        <EventItem {...props} icon={ArrowCircleUpIcon}>
          {t("driverEvents.cargoCollected", {
            amount: l10n.formatNumber(event.lm),
          })}
        </EventItem>
      );
    case "CARGO_DELIVERED":
      return (
        <EventItem {...props} icon={RoomIcon}>
          {t("driverEvents.cargoDelivered", {
            amount: l10n.formatNumber(event.lm),
          })}
        </EventItem>
      );
    case "CARGO_NOTE_ADDED":
      return (
        <EventItem {...props} icon={NoteIcon}>
          {t("driverEvents.cargoNote", { note: event.note })}
        </EventItem>
      );
    case "CARGO_DOCUMENT_ADDED":
      return (
        <EventItem
          {...props}
          icon={ArticleIcon}
          secondaryAction={
            <IconButton
              aria-label={t("driverEvents.downloadButtonLabel")}
              component="a"
              href={event.url}
            >
              <DownloadIcon />
            </IconButton>
          }
        >
          {t("driverEvents.documentAdded", {
            documentType: event.documentType,
          })}
        </EventItem>
      );
    case "CARGO_WEIGHT_ADDED":
      return (
        <EventItem {...props} icon={ScaleIcon}>
          {t("driverEvents.weightMeasured", {
            weight: l10n.formatNumber(event.weight),
          })}
        </EventItem>
      );
    case "CARGO_VOLUME_ADDED":
      return (
        <EventItem {...props} icon={SquareFootIcon}>
          {t("driverEvents.volumeMeasured", {
            volume: l10n.formatNumber(event.cubicMeters),
          })}
        </EventItem>
      );
    case "DRIVER_LOGGED_IN":
      return (
        <EventItem {...props} icon={LoginOutlinedIcon}>
          {t("driverEvents.driverLoggedIn")}
        </EventItem>
      );
    case "TRIP_STARTED":
    case "TRIP_ENDED": {
      const note = event.note;
      const odometer = l10n.formatNumber(event.odometer);
      const { heading, icon } =
        event.type === "TRIP_STARTED"
          ? {
              heading: t("driverEvents.tripStarted", { odometer }),
              icon: PlayCircleFilledWhiteOutlinedIcon,
            }
          : {
              heading: t("driverEvents.tripEnded", { odometer }),
              icon: SportsScoreOutlinedIcon,
            };
      return (
        <EventItem {...props} icon={icon}>
          {heading}
          {note && (
            <>
              <br />
              {t("driverEvents.tripNote", { note })}
            </>
          )}
        </EventItem>
      );
    }
    case "DRIVER_ARRIVED":
      return (
        <EventItem {...props} icon={AccessTimeIcon}>
          {t("driverEvents.driverArrived")}
        </EventItem>
      );
    case "DRIVER_DEPARTED":
      return (
        <EventItem {...props} icon={AccessTimeIcon}>
          {t("driverEvents.driverDeparted")}
        </EventItem>
      );
    default:
      return null;
  }
};

// const CargoEvents = ({
//   cargoId,
//   hideTitle,
// }: {
//   cargoId: string;
//   hideTitle?: boolean;
// }) => {
//   const dispatch = useAppDispatch();
//   const events = useSelector(selectCargoEvents);
//   useEffect(() => {
//     dispatch(loadCargoEvents(cargoId));
//   }, [cargoId, dispatch]);
//   const { t } = useTranslation(["cargo"]);
//   return (
//     <>
//       <Typography variant="subtitle1" component="h3">
//         {hideTitle ? "" : t("cargo:log.heading")}
//         <List>
//           {events.map((e) => (
//             <DriverEventItem key={e.id} event={e} cargoId={cargoId} />
//           ))}
//         </List>
//       </Typography>
//     </>
//   );
// };

const CargoClient = ({ cargoId }: { cargoId: string }) => {
  const clientForCargo = useSelector(selectClientForCargo(cargoId));
  return clientForCargo ? (
    <div className="flex items-center gap-1">
      <FontAwesomeIcon icon={faCircleUser} />
      {clientForCargo?.client}
    </div>
  ) : (
    <></>
  );
};

// export default CargoEvents;
